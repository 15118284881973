export default {
  "ActionResult.background_blurred": "El fondo ahora está borroso",
  "ActionResult.background_not_blurred": "El fondo ya no está borroso",
  "ActionResult.background_not_replaced": "El fondo ya no se reemplaza",
  "ActionResult.background_replaced": "El fondo ahora se reemplaza",
  "ActionResult.background_slightly_blurred": "El fondo ahora está ligeramente borroso",
  "ActionResult.camera_off": "Su cámara está apagada",
  "ActionResult.camera_on": "Su cámara está encendida",
  "ActionResult.invitation_sent": "Invitación enviada",
  "ActionResult.microphone_off": "Su micrófono está apagado",
  "ActionResult.microphone_on": "Su micrófono está encendido",
  "ActionResult.participant_admitted": "{name} es admitido",
  "ActionResult.participant_denied": "{name} es denegado",
  "BookingInfo.api": "Reservado a través de la API",
  "BookingInfo.call_centre": "Reservado por {booker} a través del Call Center",
  "BookingInfo.kiosk": "Reservado por {booker} a través del quiosco",
  "BookingInfo.lobby": "Reservado por {booker} a través del Lobby",
  "BookingInfo.modern_client_view": "Reservado por {booker} a través de Modern Client View",
  "BookingInfo.online_queue": "Reservado por {booker} a través de la cola en línea",
  "BookingInfo.outlook_add_in": "Reservado por {booker} a través del complemento de Outlook",
  "BookingInfo.schedule": "Reservado por {booker} a través del Horario",
  "BookingInfo.tv": "Reservado por {booker} a través de la TV",
  "BookingInfo.unknown": "No disponible",
  "ChangeBackgroundOptions.blur": "Desenfoca su fondo",
  "ChangeBackgroundOptions.blur_slightly": "Difumina ligeramente su fondo",
  "ChangeBackgroundOptions.custom_background": "Fondo personalizado {number}",
  "ChangeBackgroundOptions.custom_background_name": "Reemplazar fondo con {name}",
  "ChangeBackgroundOptions.loading": "Cargando fondos virtuales",
  "ChangeBackgroundOptions.none": "Desactivar cambios de fondo",
  "Chat.failed": "Fallo al enviar",
  "Chat.i_say": "Yo digo:",
  "Chat.send_message": "Envía un mensaje a todos",
  "Chat.sending_message": "Enviando mensaje",
  "Chat.someone_says": "{name} dice:",
  "Chat.title": "Chat",
  "Chat.unread_messages": "Tienes mensajes sin leer",
  "Chat.warn_about_phone_participants": "Los participantes del teléfono no pueden leer los mensajes de chat",
  "ChatBot.find_real_person": "¡Hola, {name}! Voy a encontrarte una persona real con quien chatear.",
  "ChatBot.invite_to_audio_call": "¡Yo otra vez! {name} te ha invitado a unirte a una llamada de audio: {url} (el enlace se abre en una pestaña nueva)",
  "ChatBot.invite_to_video_call": "¡Yo otra vez! {name} te ha invitado a unirte a una videollamada: {url} (el enlace se abre en una pestaña nueva)",
  "ChatBot.name": "Robot de chat",
  "ChatDashboard.closed_chats_title": "Chats cerrados",
  "ChatDashboard.my_chats": "Mis chats ( {number} )",
  "ChatDashboard.open_chats_title": "Chats abiertos",
  "ChatDashboard.unassigned": "Sin asignar ( {number} )",
  "ChatEvent.assigned": "Chat asignado a usted: {time}",
  "ChatEvent.closed": "{user} cerró el chat: {time}",
  "ChatEvent.joined": "{user} se unió al chat: {time}",
  "ChatEvent.reopened": "{user} reabrió el chat: {time}",
  "ChatEvent.started": "Chat iniciado: {time}",
  "ChatWidget.email": "Correo electrónico:",
  "ChatWidget.hi": "¡Hola!",
  "ChatWidget.maximize": "Maximizar ventana",
  "ChatWidget.minimize": "Minimizar ventana",
  "ChatWidget.modal_title": "Chatea con alguien de {name}",
  "ChatWidget.send_message": "Enviar un mensaje",
  "ChatWidget.start": "Comenzar chat",
  "ChatWidget.title": "Charlar",
  "ChatWidget.your_name": "Su nombre:",
  "ChatWidgetActionsButton.stop_chat": "Detener el chat",
  "ChatWidgetActionsButton.stop_chat_description": "¿Está seguro de que desea detener el chat?",
  "ChatWidgetActionsButton.stop_chat_title": "¿Dejar de chatear?",
  "ChatWidgetActionsButton.yes_stop_chat": "Sí, deja de chatear",
  "ClosedCaptionsConnectionStatus.cannot_connect": "No podemos conectarnos a nuestro servicio de subtítulos.",
  "ClosedCaptionsConnectionStatus.connecting": "Conexión al servicio de subtítulos ocultos.",
  "ClosedCaptionsConnectionStatus.reconnecting": "Nuestro servicio de subtítulos no responde. Estamos tratando de reconectarnos.",
  "CoBrowse.cancel": "Cancelar",
  "CoBrowse.co_browse_webpage": "Co-navegar una página web",
  "CoBrowse.connected_description": "La navegación conjunta comenzará una vez que todos se hayan conectado",
  "CoBrowse.empty": "No tienes ninguna página web de navegación conjunta",
  "CoBrowse.in_progress_description": "Navegación conjunta actualmente",
  "CoBrowse.in_use": "Alguien más está compartiendo la pantalla",
  "CoBrowse.in_your_use": "Ya estás compartiendo pantalla",
  "CoBrowse.loading_description": "Asegurarse de que la página pueda ser co-navegada",
  "CoBrowse.select_page": "Seleccione una página para co-navegar",
  "CoBrowse.socket_error": "Bloqueado por elementos como bloqueadores de anuncios, bloqueadores de cookies o cortafuegos",
  "CoBrowse.stop": "Deténgase",
  "CoBrowse.unsupported": "Esta URL no admite la navegación conjunta",
  "CoBrowse.warn_about_phone_participants": "Los participantes del teléfono no pueden navegar juntos",
  "CoBrowseDialog.cancelled_description": "{name} ha cerrado su sesión de navegación conjunta",
  "CoBrowseDialog.iframe_failed": "Ocurrió un error al cargar la sesión por parte de uno de los participantes",
  "CoBrowseDialog.interrupted": "Co-navegación interrumpida",
  "CoBrowseDialog.interrupted_description": "{name} intentó iniciar una sesión de navegación conjunta, pero se produjo un error",
  "CoBrowseDialog.stop_co_browsing": "Dejar de co-navegar",
  "CoBrowseDialog.try_again": "Intentar otra vez",
  "CoBrowsingFrame.loading_page": "Cargando página",
  "CoBrowsingFrame.participant_is_navigator": "{name} ahora es el navegante",
  "CoBrowsingFrame.unsupported": "Esta página no admite la navegación conjunta",
  "CoBrowsingFrame.you_are_navigator": "Ahora eres el navegador",
  "CoBrowsingHeader.co_browsing": "Co-navegación",
  "CoBrowsingHeader.refresh": "Actualizar",
  "CoBrowsingHeader.session_failed_to_load": "No se pudo cargar la sesión de navegación conjunta. Puedes intentar actualizarlo.",
  "CoBrowsingHeader.stop_co_browsing": "Dejar de co-navegar",
  "CoBrowsingHeader.take_over_as_navigator": "Asumir como navegador",
  "CoBrowsingHeader.you_are_navigator": "Usted es el navegante",
  "ContactSupport.description": "Si necesita ponerse en contacto con Soporte, incluya el siguiente desorden de caracteres con su mensaje: es un identificador único del problema que ayudará a que se diagnostique más rápidamente 🙂",
  "ContactSupport.title": "Consigue ayuda",
  "ConversationsDashboard.empty_description": "Envíe mensajes de texto a clientes vía SMS y manténgalos todos en un solo lugar, aquí mismo",
  "ConversationsDashboard.new_conversation": "Nueva conversación",
  "ConversationsDashboard.start_conversation": "Iniciar una conversación",
  "ConversationsDashboard.title": "Conversaciones",
  "Details.title": "Detalles de la reunión",
  "DocumentIntegrationLogin.choose_platform": "Elija una plataforma de firma electrónica",
  "DocumentIntegrationLogin.choose_platform_description": "Sus documentos de firma electrónica estarán disponibles aquí después de conectarse",
  "DocumentIntegrationLogin.connect": "Conectar",
  "DocumentIntegrationLogin.continue": "Continuar con esta cuenta",
  "DocumentIntegrationLogin.dialog_description": "No ha iniciado sesión en la cuenta de DocuSign de su organización ( {account} ). Esto podría resultar en que usted o los firmantes no puedan acceder a los documentos y sobres de su organización durante la llamada.",
  "DocumentIntegrationLogin.dialog_title": "¿Iniciaste sesión con la cuenta de DocuSign correcta?",
  "DocumentIntegrationLogin.one_span_error_description": "Su correo electrónico no existe en la cuenta OneSpan Sign de su organización",
  "DocumentIntegrationLogin.one_span_error_title": "La conexión a OneSpan Sign falló",
  "DocumentIntegrationLogin.use_different_account": "Ingrese con una cuenta diferente",
  "DocumentReview.back": "Volver a revisar",
  "DocumentReview.back_to_review": "Sí, volver a revisar",
  "DocumentReview.back_to_review_description": "¿Está seguro de que desea salir del proceso de firma y volver a la revisión ahora?",
  "DocumentReview.back_to_review_title": "¿Volver a revisar?",
  "DocumentReview.cancelled_by": "Cancelado por",
  "DocumentReview.cancelled_signing": "Firma cancelada",
  "DocumentReview.close": "Salir de revisión",
  "DocumentReview.close_document": "Cerrar documento",
  "DocumentReview.close_signing": "Firma de salida",
  "DocumentReview.currently_signing": "Firmando actualmente",
  "DocumentReview.declined_by": "Rechazado por",
  "DocumentReview.document_already_signed": "La firma no puede continuar porque el documento ya se ha firmado fuera de esta convocatoria.",
  "DocumentReview.document_cannot_be_signed": "Este documento ya no se puede firmar",
  "DocumentReview.document_declined": "La firma no puede continuar porque algunos firmantes se han negado a firmar el documento fuera de esta llamada.",
  "DocumentReview.document_expired": "La firma no puede continuar porque el acceso al documento ha caducado.",
  "DocumentReview.document_voided": "La firma no puede continuar porque el documento ha sido anulado fuera de esta convocatoria.",
  "DocumentReview.done_signing": "Terminado de firmar",
  "DocumentReview.exit_signing": "Sí, firma de salida",
  "DocumentReview.exit_signing_description": "¿Está seguro de que desea salir del proceso de firma ahora?",
  "DocumentReview.exit_signing_title": "¿Firma de salida?",
  "DocumentReview.failed_to_start_session": "No se pudo iniciar la sesión de firma",
  "DocumentReview.failed_to_start_session_description": "Se ha producido un error al intentar iniciar la sesión de firma.",
  "DocumentReview.in_progress": "Inicio de sesión en curso",
  "DocumentReview.input": "Documento",
  "DocumentReview.loading": "Cargando documento",
  "DocumentReview.match_signer": "Coincide con {name}",
  "DocumentReview.match_to": "Coincidir con",
  "DocumentReview.no_participants_to_match": "No hay participantes para emparejar",
  "DocumentReview.resume": "Reanudar",
  "DocumentReview.signers_missing": "Faltan algunos firmantes",
  "DocumentReview.signers_missing_description": "Las direcciones de correo electrónico de algunos firmantes no coinciden con nadie en esta llamada. Haga coincidir el correo electrónico con alguien en la llamada o continúe sin hacer coincidir.",
  "DocumentReview.signers_missing_from_call": "Faltan algunos firmantes de la convocatoria.",
  "DocumentReview.signing_cancelled_title": "Firma cancelada",
  "DocumentReview.signing_cannot_continue": "El proceso de firma no puede continuar.",
  "DocumentReview.signing_cannot_continue_local": "Otros no pueden firmar hasta que usted reanude y termine.",
  "DocumentReview.signing_cannot_continue_until": "El proceso de firma no puede continuar hasta que terminen.",
  "DocumentReview.signing_ceremony": "Ceremonia de firma",
  "DocumentReview.signing_completed_title": "Firma completa",
  "DocumentReview.signing_declined_title": "Firma rechazada",
  "DocumentReview.signing_exception_description": "Se produjo un error del sistema durante la firma de <strong> {envelope} </strong>",
  "DocumentReview.signing_exception_title": "Algo salió mal",
  "DocumentReview.signing_in_progress": "Inicio de sesión en curso",
  "DocumentReview.signing_incomplete_description": "Algunos firmantes cancelaron, posiblemente para terminar más tarde.",
  "DocumentReview.signing_incomplete_description_local": "Cancelaste el proceso de firma.",
  "DocumentReview.signing_incomplete_title": "Firma incompleta",
  "DocumentReview.signing_title": "Firma: <strong> {name} </strong>",
  "DocumentReview.start_signing": "Empezar a firmar",
  "DocumentReview.start_when_your_turn": "Su sesión comenzará automáticamente cuando sea su turno.",
  "DocumentReview.stay_in_signing": "Permanecer en la firma",
  "DocumentReview.title": "Reseña: <strong> {name} </strong>",
  "DocumentReview.verify_signers_identities": "Asegúrese de haber verificado las identidades de los firmantes antes de hacer coincidir",
  "DocumentReview.waiting": "Esperando a que comience la firma",
  "DocumentReview.waiting_to_sign": "Esperando para firmar",
  "Documents.add": "Agregar documentos de {provider}",
  "Documents.already_added": "Ya agregado",
  "Documents.connecting_to": "Conectando a {provider}",
  "Documents.declined": "Rechazado",
  "Documents.declined_description": "Los documentos rechazados no se pueden firmar",
  "Documents.disconnect": "Desconectar",
  "Documents.empty": "No hay documentos",
  "Documents.envelope_document_count": "{count, plural, one {# documento} other {# documentos}}",
  "Documents.envelope_documents": "Documentos para {envelope}",
  "Documents.envelopes": "Sobres seleccionados",
  "Documents.expired": "Venció",
  "Documents.expired_description": "Los documentos caducados no se pueden firmar.",
  "Documents.hide_documents": "Ocultar documentos",
  "Documents.loading": "Cargando documentos",
  "Documents.no_document": "Sin documento",
  "Documents.partially_signed": "Parcialmente firmado",
  "Documents.review_and_sign": "Revisar juntos y firmar",
  "Documents.review_together": "Revisar juntos",
  "Documents.show_documents": "Mostrar documentos",
  "Documents.signable_envelope_document_count": "{count, plural, one { <div> {state} </div> - # documento } other { <div> {state} </div> - # documentos }}",
  "Documents.signed": "Firmado",
  "Documents.signed_description": "Ha sido firmado",
  "Documents.title": "Documentos",
  "Documents.unsigned": "No firmado",
  "Documents.uploading_document": "Subiendo el documento",
  "Documents.voided": "Anulado",
  "Documents.voided_description": "Los documentos anulados no se pueden firmar",
  "Documents.warn_about_phone_participants": "Los participantes telefónicos no pueden acceder a los documentos",
  "EndCallButton.title": "Dejar llamada",
  "Feedback.comment_help_text": "Sus comentarios son anónimos.",
  "Feedback.comment_label": "¿Qué mejoraría su experiencia?",
  "Feedback.dissatisfied": "Insatisfecho",
  "Feedback.neutral": "Neutral",
  "Feedback.satisfied": "Satisfecho",
  "Feedback.send_feedback": "Enviar comentarios",
  "Feedback.thanks": "¡Gracias por sus comentarios!",
  "Feedback.title_chat": "¿Cómo fue su experiencia en el chat?",
  "Feedback.title_connect": "¿Cómo fue su experiencia con la videollamada?",
  "ForgotPassword.description": "Ingrese su correo electrónico y le enviaremos un enlace para restablecer su contraseña",
  "ForgotPassword.go_back": "Volver para iniciar sesión",
  "ForgotPassword.loading": "Le estamos enviando un correo electrónico.",
  "ForgotPassword.success_description": "Le enviamos un enlace para restablecer su contraseña",
  "ForgotPassword.success_title": "Revisa su correo",
  "ForgotPassword.title": "¿Olvidaste su contraseña?",
  "InternalNotes.label": "Notas",
  "InternalNotes.loading": "Cargando notas",
  "InternalNotes.subtitle": "Las notas internas no son visibles para los clientes.",
  "InternalNotes.title": "Notas Internas",
  "Login.forgot_password": "¿Has olvidado su contraseña?",
  "Login.loading": "Iniciar sesión en Coconut",
  "Login.title": "Iniciar sesión en Coco",
  "Login.zoom_description": "Inicie sesión en Coconut para conectarse a Zoom",
  "MediaErrorAlert.GeneralError.title": "Error al adquirir el micrófono o la cámara",
  "MediaErrorAlert.NoCameraError.description": "No se detectó ninguna cámara. Los demás participantes en la sala no podrán verlo.",
  "MediaErrorAlert.NoDeviceError.description": "No se ha detectado ninguna cámara ni micrófono. Los demás participantes no podrán escucharlo ni verlo.",
  "MediaErrorAlert.NoMicrophoneError.description": "No se detectó ningún micrófono. Los demás participantes en la sala no podrán escucharlo.",
  "MediaErrorAlert.NotAllowedError.description_denied_by_os": "No podemos acceder a su micrófono o cámara debido a la configuración del sistema operativo. Por favor, compruebe la configuración de su sistema operativo.",
  "MediaErrorAlert.NotAllowedError.description_denied_by_user": "No podemos acceder a su micrófono o cámara debido a problemas de permisos",
  "MediaErrorAlert.NotFoundError.description": "El navegador no puede acceder al micrófono o la cámara. Asegúrese de que los dispositivos estén conectados y habilitados.",
  "MediaErrorAlert.NotReadableError.description": "El navegador no puede iniciar la captura de medios. Cierre todas las demás aplicaciones y pestañas que usan el micrófono o la cámara y actualice la página.",
  "MediaErrorAlert.NotReadableError.description_audio": "No se detectó ningún micrófono. Cierre todas las demás aplicaciones y pestañas que usan el micrófono y actualice la página.",
  "MediaErrorAlert.NotReadableError.description_video": "No se detectó ninguna cámara. Cierre todas las demás aplicaciones y pestañas que usan la cámara y actualice la página.",
  "MediaErrorAlert.learn_how_to_fix": "Aprende cómo arreglar esto",
  "MediaErrorSnackbar.details": "Detalles",
  "Meeting.attendees": "Asistentes",
  "Meeting.bitrate_threshold_description": "Es posible que experimente problemas con la calidad de audio/video durante su llamada.",
  "Meeting.call_access_blocked": "Su acceso a esta llamada está bloqueado",
  "Meeting.call_access_blocked_description": "Lamentamos dejarte varado. Su acceso a esta llamada está siendo bloqueado actualmente por una configuración del sistema como un firewall o un proxy. Póngase en contacto con su soporte de TI para obtener ayuda.",
  "Meeting.cancelled": "Esta reunión ha sido cancelada.",
  "Meeting.disconnected": "Le fuiste de la reunión",
  "Meeting.ended": "Esta reunión ha concluído",
  "Meeting.in_call": "En llamada",
  "Meeting.is_appointment_done": "¿Su cita está hecha?",
  "Meeting.join_now": "Únete ahora",
  "Meeting.joining": "Unirse a la reunión",
  "Meeting.joining_now": "Unirse ahora",
  "Meeting.manage": "Gestionar cita",
  "Meeting.meeting_title": "Reunión con {name}",
  "Meeting.not_in_call": "No en llamada",
  "Meeting.page_title": "Unirse a una reunión",
  "Meeting.participant_cancelled": "Su invitación fue cancelada",
  "Meeting.preview_settings": "Vista previa de su configuración",
  "Meeting.recording": "El audio de esta reunión se está grabando con fines de calidad.",
  "Meeting.rejoin": "Reunirse con",
  "Meeting.rejoining": "Volver a unirse",
  "Meeting.removed_from_call": "Fuiste eliminado de la llamada",
  "Meeting.scheduled_for": "Esta reunión está programada para <strong> {date} </strong>",
  "Meeting.something_went_wrong": "Algo salió mal",
  "Meeting.starting": "Comenzando reunión",
  "Meeting.stranded": "Lamentamos dejarte varado. {br} Estamos trabajando duro para solucionar este problema.",
  "Meeting.temporarily_unavailable": "Esta llamada no está disponible temporalmente",
  "Meeting.too_early": "Eres un poco temprano",
  "Meeting.unable_to_connect": "No podemos conectarnos a su reunión",
  "Meeting.update_appointment_status": "¡Estupendo! No olvides actualizar el estado de la cita",
  "MeetingDetails.add_notes": "Agregar notas",
  "MeetingDetails.additional_details": "Detalles adicionales",
  "MeetingDetails.attendees": "Asistentes",
  "MeetingDetails.booking_info": "Información de reserva",
  "MeetingDetails.clients": "Clientes ({count})",
  "MeetingDetails.edit_notes": "Editar",
  "MeetingDetails.internal_notes": "Notas Internas",
  "MeetingDetails.loading": "Cargando detalles de la reunión",
  "MeetingDetails.many_participants_title": "{first} , {second} , + {count} más - {service}",
  "MeetingDetails.no_answer": "Sin respuesta",
  "MeetingDetails.no_participants_title": "{service}",
  "MeetingDetails.private_question": "<span> Privado: </span> {label}",
  "MeetingDetails.questions": "Preguntas",
  "MeetingDetails.single_participant_title": "{first} - {service}",
  "MeetingDetails.subtitle": "Hoy, {start} - {end} ({timezone})",
  "MeetingDetails.three_participants_title": "{first} , {second} , {third} - {service}",
  "MeetingDetails.two_participants_title": "{first} y {second} - {service}",
  "MeetingDetails.users": "Personal ({count})",
  "NoConversationSelected.description": "Seleccione una conversación para verla o inicie una nueva",
  "ParticipantActions.camera_is_off": "Su cámara está apagada",
  "ParticipantActions.camera_off": "Cámara apagada",
  "ParticipantActions.cannot_mute_phone_participant": "No puede silenciar a un participante telefónico",
  "ParticipantActions.cannot_turn_on_camera": "No puedes encender la cámara de alguien.",
  "ParticipantActions.cannot_unmute": "No puedes dejar de silenciar a alguien",
  "ParticipantActions.mark_verified": "Marcar como verificado",
  "ParticipantActions.muted": "Apagado",
  "ParticipantActions.only_organizer_can_remove": "Solo el Organizador puede eliminar personas",
  "ParticipantActions.participant_verified": "{name} está verificado",
  "ParticipantActions.take_screenshot": "Tomar captura de pantalla",
  "ParticipantActions.verified": "Verificado",
  "ParticipantActions.verifying": "Verificando",
  "ParticipantInfo.avatar_label": "Avatar de {name}",
  "ParticipantInfo.avatar_local_label": "Avatar de {name} (usted)",
  "ParticipantInfo.email": "Correo electrónico del asistente",
  "ParticipantInfo.invite_another": "Invita a otro asistente o comparte la información para unirse",
  "ParticipantInfo.invited_participant": "Enviado a <b> {email} </b>",
  "ParticipantInfo.inviting_participant": "Enviando a <b> {email} </b>",
  "ParticipantInfo.joining_info": "Información de unión",
  "ParticipantInfo.local_name": "{name} (usted)",
  "ParticipantInfo.meeting_ready": "Su reunión está lista",
  "ParticipantInfo.name_not_in_call": "{name} (no en la llamada)",
  "ParticipantInfo.navigator_name": "{name} (navegador)",
  "ParticipantInfo.send": "Invitación",
  "ParticipantInfo.you": "Usted",
  "Participants.participant_left_meeting": "{name} ha abandonado la reunión",
  "Participants.participant_removed_from_call": "{name} fue eliminado de la llamada",
  "People.add_another": "Agregar otro correo electrónico",
  "People.cancel_invitation": "Cancelar invitación",
  "People.in_call": "En llamada",
  "People.invite": "Invitación",
  "People.invite_others": "Invitar a otros a unirse",
  "People.invited": "Invitado",
  "People.joined_by_phone": "Unido por teléfono",
  "People.no_email": "El invitado no tiene dirección de correo electrónico",
  "People.no_show": "No show",
  "People.removal_is_permanent": "La eliminación de personas de la llamada no se puede deshacer",
  "People.remove_email": "Eliminar {email}",
  "People.remove_from_call": "Eliminar de la llamada",
  "People.resend_invitation": "Reenviar invitacíon",
  "People.send_invitations": "Enviar invitaciones",
  "People.unverified_identities": "Identidades no verificadas",
  "People.unverified_identities_description": "Algunas personas en la llamada no han verificado sus identidades",
  "People.waiting": "Esperando ser admitido",
  "Position.label": "{start} - {end} de {total}",
  "Position.label_single_item": "{start} de {total}",
  "PreChatSurvey.description": "Un ser humano real (en serio) está aquí para ayudarte. Pero primero necesitamos su nombre y correo electrónico para saber con quién estamos hablando.",
  "PreChatSurvey.title": "Nos encantaría charlar",
  "PreJoinScreen.ask_to_join": "Pide unirte",
  "PreJoinScreen.denied": "Su entrada a esta llamada fue denegada",
  "PreJoinScreen.email_label": "Su correo electrónico",
  "PreJoinScreen.forbidden_description": "Esta reunión está en curso con todos los asistentes permitidos",
  "PreJoinScreen.forbidden_title": "No puedes unirte a esta reunión",
  "PreJoinScreen.join_by_phone": "Únase por teléfono: <a> {number} </a>",
  "PreJoinScreen.join_by_phone_label": "Únete por teléfono",
  "PreJoinScreen.join_by_phone_warn_organizer": "No puedes unirte por teléfono como organizador",
  "PreJoinScreen.name_label": "Nombre completo",
  "PreJoinScreen.other_user_prompt": "¿No es {name} ?",
  "PreJoinScreen.pin": "PIN: {number}",
  "PreJoinScreen.redirecting": "Comprobando si has iniciado sesión",
  "PreJoinScreen.sign_in": "Iniciar sesión",
  "PreJoinScreen.sign_in_to_join": "Inicia sesión para unirte",
  "PreJoinScreen.waiting": "Esperando a que alguien te deje entrar",
  "PreJoinScreen.work_at_tenant": "¿Trabajas en {name} ?",
  "ResetPassword.description": "Introduzca una nueva contraseña para:",
  "ResetPassword.failed_description": "Algo anda mal con el enlace que usaste para llegar aquí. Vuelva a intentarlo con el enlace de su correo electrónico.",
  "ResetPassword.failed_title": "No puedo restablecer la contraseña",
  "ResetPassword.loading": "Configuración de su nueva contraseña",
  "ResetPassword.password": "Nueva contraseña",
  "ResetPassword.password_helper_text": "Debe tener al menos 10 caracteres",
  "ResetPassword.show_password": "Mostrar contraseña",
  "ResetPassword.success_title": "Su contraseña está configurada",
  "ResetPassword.title": "Restablecer su contraseña",
  "Room.people_are_waiting": "La gente está esperando para ser admitidos a la llamada",
  "Room.recording": "Grabación de audio",
  "Room.screen_being_shared": "{name} está compartiendo su pantalla",
  "Room.someone_was_invited": "{name} invitó a alguien a la llamada",
  "Room.vortex": "¡Ayuda! estoy en un vórtice",
  "Room.you_are_sharing_screen": "Estás compartiendo su pantalla",
  "RoomHeader.maximize": "Maximizar",
  "RoomHeader.minimize": "Minimizar",
  "RoomHeader.unstable_connection": "Su conexión es inestable",
  "RoomTimerDialog.are_you_still_there": "¿Sigues ahí?",
  "RoomTimerDialog.leave_call": "Dejar llamada",
  "RoomTimerDialog.stay_in_call": "Quédate en la llamada",
  "RoomTimerDialog.this_call_will_end": "Eres el único aquí. Esta llamada terminará en menos de 2 minutos, a menos que decidas quedarte.",
  "Settings.camera_label": "Cámara",
  "Settings.microphone_label": "Micrófono",
  "Settings.speakers_label": "Altavoces",
  "Settings.title": "Ajustes",
  "SocketErrorAlert.description": "Algunas funciones de colaboración están siendo bloqueadas por cosas como bloqueadores de anuncios, bloqueadores de cookies o firewalls.",
  "SpecificChat.assign_to_me": "Asigname",
  "SpecificChat.assigned_to": "Este chat fue asignado a {name}",
  "SpecificChat.close_and_archive": "Cerrar y archivar",
  "SpecificChat.closed": "Este chat esta cerrado",
  "SpecificChat.hide_profile": "Ocultar información de perfil",
  "SpecificChat.pre_chat_survey": "Encuesta previa al chat",
  "SpecificChat.profile": "Perfil",
  "SpecificChat.reopen": "Reabrir",
  "SpecificChat.show_profile": "Mostrar información de perfil",
  "SpecificChat.start_audio_call": "Iniciar una llamada de audio",
  "SpecificChat.start_video_call": "Iniciar una videollamada",
  "SpecificChat.stopped": "Este chat fue detenido",
  "SpecificChat.unassigned": "Este chat no está asignado",
  "SpecificChat.viewer": "eres el unico que lo ve",
  "SpecificChat.viewers": "Tú y {count, plural, one { otra persona } other { otras {count} personas }} lo están viendo actualmente",
  "SpecificChatActionsButton.close_chat": "Cerrar chat y archivar",
  "SpecificChatActionsButton.close_chat_description": "¿Está seguro de que desea cerrar este chat y archivarlo?",
  "SpecificChatActionsButton.close_chat_title": "¿Cerrar y archivar?",
  "SpecificChatActionsButton.yes_close_chat": "Sí, cerrar y archivar",
  "SpecificChatViewers.more": "+ {count} más",
  "ToggleAppointmentDetailsButton.close": "Cerrar detalles de la reunión",
  "ToggleAppointmentDetailsButton.details": "Detalles de la reunión",
  "ToggleAudioButton.mute": "Silencio",
  "ToggleAudioButton.unmute": "No silenciar",
  "ToggleCaptionsButton.off": "Desactivar subtítulos",
  "ToggleCaptionsButton.on": "Activar subtítulos",
  "ToggleChangeBackgroundButton.close": "Cerrar Cambiar fondo",
  "ToggleChangeBackgroundButton.open": "Cambiar el fondo",
  "ToggleChangeBackgroundButton.unavailable_with_this_browser": "No disponible con este navegador",
  "ToggleChatButton.close": "Cerrar charla",
  "ToggleChatButton.open": "Chat",
  "ToggleCoBrowseButton.close_co_browse": "Cerrar Co-navegación",
  "ToggleDocumentsButton.close": "Cerrar Documentos",
  "ToggleDocumentsButton.documents": "Documentos",
  "ToggleInternalNotesButton.close": "Cerrar notas internas",
  "ToggleInternalNotesButton.notes": "Notas Internas",
  "ToggleMoreButton.more": "Más",
  "TogglePeopleButton.close": "Gente cercana",
  "TogglePeopleButton.open": "Gente",
  "ToggleScreenShareButton.co_browse_in_use": "La navegación compartida está activa",
  "ToggleScreenShareButton.in_use": "Alguien más está compartiendo la pantalla",
  "ToggleScreenShareButton.share": "Compartir pantalla",
  "ToggleScreenShareButton.share_with_phone_participants": "Compartir pantalla (no visible para los participantes del teléfono)",
  "ToggleScreenShareButton.stop_sharing": "Deja de compartir",
  "ToggleScreenShareButton.unavailable_on_device": "No disponible en este dispositivo",
  "ToggleScreenShareButton.warn_about_phone_participants": "Los participantes del teléfono no pueden ver las pantallas compartidas",
  "ToggleSettingsButton.close_settings": "Cerrar configuración",
  "ToggleSettingsButton.settings": "Ajustes",
  "ToggleVideoButton.off": "Apaga la cámara",
  "ToggleVideoButton.on": "Encienda la camara",
  "TwilioComponentAlert.degraded_audio_description": "Es posible que experimente alguna interrupción si se une por teléfono. Estamos trabajando para arreglar esto.",
  "TwilioComponentAlert.degraded_audio_title": "Unirse por teléfono tiene problemas",
  "TwilioComponentAlert.degraded_video_description": "Es posible que experimente alguna interrupción en su video durante la llamada. Estamos trabajando para arreglar esto.",
  "TwilioComponentAlert.degraded_video_title": "La pantalla de video tiene problemas",
  "TwilioComponentAlert.unavailable_audio_description": "Todavía puedes unirte a esta llamada por video. Estamos trabajando para arreglar esto.",
  "TwilioComponentAlert.unavailable_audio_title": "Unirse por teléfono no está disponible actualmente",
  "TwilioComponentAlert.unavailable_video_description": "Todavía puede unirse a esta llamada usando solo audio. Estamos trabajando para arreglar esto.",
  "TwilioComponentAlert.unavailable_video_title": "El vídeo no está disponible actualmente",
  "TwilioComponentAlert.under_maintenance_audio_description": "Todavía puedes unirte a esta llamada por video.",
  "TwilioComponentAlert.under_maintenance_audio_title": "Unirse por teléfono está actualmente en mantenimiento",
  "TwilioComponentAlert.under_maintenance_video_description": "Todavía puede unirse a esta llamada usando solo audio.",
  "TwilioComponentAlert.under_maintenance_video_title": "El video está actualmente en mantenimiento.",
  "Ui.admit": "Admitir",
  "Ui.close": "Cerca",
  "Ui.closed": "Cerrado",
  "Ui.continue": "Continuar",
  "Ui.copied": "¡Copiado!",
  "Ui.copy": "Dupdo",
  "Ui.count_more": "+ {count} más",
  "Ui.deny": "Negar",
  "Ui.details": "Detalles",
  "Ui.dismiss": "Descartar",
  "Ui.download": "Descargar",
  "Ui.email": "Correo electrónico",
  "Ui.go_back": "Regresa",
  "Ui.got_it": "Entiendo",
  "Ui.hide_people": "Ocultar personas",
  "Ui.loading": "Cargando...",
  "Ui.more": "Más",
  "Ui.next": "Próximo",
  "Ui.open": "Abierto",
  "Ui.other": "Otro",
  "Ui.password": "Clave",
  "Ui.refresh": "Actualizar",
  "Ui.refresh_contents": "Actualizar contenido",
  "Ui.refreshed": "{name} se actualizó",
  "Ui.refreshing": "Refrescante",
  "Ui.remove": "Eliminar",
  "Ui.resend": "Reenviar",
  "Ui.send": "Enviar",
  "Ui.show_less": "Muestra menos",
  "Ui.show_more": "Mostrar más",
  "Ui.show_people": "Mostrar personas",
  "Ui.sign_in": "Iniciar sesión",
  "Ui.sign_in_failed_email_description": "El correo electrónico y/o la contraseña eran incorrectos",
  "Ui.sign_in_failed_title": "No pude iniciar sesión",
  "Ui.sign_in_failed_username_description": "El nombre de usuario y/o la contraseña eran incorrectos",
  "Ui.try_again": "Intentar otra vez",
  "Ui.upload": "Subir",
  "Ui.username_or_email": "Nombre de usuario o correo electrónico",
  "Ui.your_name": "Su nombre",
  "UnsupportedBrowser.browser_suggestion": "Vuelva a intentarlo con un navegador más nuevo, como Chrome o Firefox.",
  "UnsupportedBrowser.browser_suggestion_android": "Vuelva a intentarlo con una versión más reciente de Chrome o Firefox.",
  "UnsupportedBrowser.browser_suggestion_ios": "Vuelva a intentarlo con una versión más reciente de Safari.",
  "UnsupportedBrowser.browser_suggestion_mac": "Vuelva a intentarlo con una versión más reciente de Chrome, Firefox, Safari o Edge.",
  "UnsupportedBrowser.browser_suggestion_windows": "Vuelva a intentarlo con una versión más reciente de Chrome, Firefox o Edge.",
  "UnsupportedBrowser.browser_suggestion_windows_phone": "Vuelva a intentarlo con una versión más reciente de Edge.",
  "UnsupportedBrowser.description": "No podemos conectarte porque su navegador web no admite este tipo de videollamadas.",
  "UnsupportedBrowser.title": "Su navegador no es compatible",
  "Validation.email_required": "El campo de correo electrónico es obligatorio",
  "Validation.email_valid": "El correo electrónico debe ser una dirección de correo electrónico válida",
  "Validation.emails_required": "Añade al menos una dirección de correo electrónico",
  "Validation.emails_unique": "El correo electrónico ya ha sido proporcionado.",
  "Validation.full_name_required": "El campo de nombre completo es obligatorio",
  "Validation.name_required": "El campo de nombre es obligatorio",
  "Validation.password_length": "Debe tener al menos 10 caracteres",
  "Validation.password_required": "El campo de contraseña es obligatorio",
  "Validation.url_required": "El campo URL es obligatorio",
  "Validation.url_valid": "La URL debe ser una URL válida",
  "Validation.username_or_email_required": "El campo de nombre de usuario o correo electrónico es obligatorio",
  "VideoDegradedHeader.degraded": "El video tiene problemas actualmente. Estamos trabajando para arreglar esto.",
  "VideoDegradedHeader.unavailable": "El vídeo no está disponible actualmente. Estamos trabajando para arreglar esto.",
  "VideoTrack.stop_sharing": "Deja de compartir",
  "VideoTrack.you_are_sharing": "Estás compartiendo su pantalla",
  "VortexDialog.ok": "Entiendo",
  "VortexDialog.stop_sharing": "Deja de compartir",
  "VortexDialog.title": "¿Efecto vórtice?",
  "VortexDialog.vortex_description": "Si experimenta un espejo infinito en su pantalla, asegúrese de no estar compartiendo la pantalla, la ventana o la pestaña que tiene la videollamada. Intente compartir solo una pestaña o una ventana diferente."
}
