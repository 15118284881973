export default {
  "ActionResult.background_blurred": "L'arrière-plan est maintenant flou",
  "ActionResult.background_not_blurred": "L'arrière-plan n'est plus flou",
  "ActionResult.background_not_replaced": "L'arrière-plan n'est plus remplacé",
  "ActionResult.background_replaced": "L'arrière-plan est maintenant remplacé",
  "ActionResult.background_slightly_blurred": "L'arrière-plan est maintenant légèrement flou",
  "ActionResult.camera_off": "Votre caméra est éteinte",
  "ActionResult.camera_on": "Votre caméra est allumée",
  "ActionResult.invitation_sent": "Invitation envoyée",
  "ActionResult.microphone_off": "Votre micro est éteint",
  "ActionResult.microphone_on": "Votre micro est activé",
  "ActionResult.participant_admitted": "{name} est admis",
  "ActionResult.participant_denied": "{name} est refusé",
  "BookingInfo.api": "Réservé via l'API",
  "BookingInfo.call_centre": "Réservé par {booker} via le centre d'appels",
  "BookingInfo.kiosk": "Réservé par {booker} via le kiosque",
  "BookingInfo.lobby": "Réservé par {booker} via le hall",
  "BookingInfo.modern_client_view": "Réservé par {booker} via la vue client moderne",
  "BookingInfo.online_queue": "Réservé par {booker} via la file d'attente en ligne",
  "BookingInfo.outlook_add_in": "Réservé par {booker} via le complément Outlook",
  "BookingInfo.schedule": "Réservé par {booker} via le calendrier",
  "BookingInfo.tv": "Réservé par {booker} via le téléviseur",
  "BookingInfo.unknown": "Indisponible",
  "ChangeBackgroundOptions.blur": "Flou votre arrière-plan",
  "ChangeBackgroundOptions.blur_slightly": "Estompez légèrement votre arrière-plan",
  "ChangeBackgroundOptions.custom_background": "Arrière-plan personnalisé {number}",
  "ChangeBackgroundOptions.custom_background_name": "Remplacer l'arrière-plan par {name}",
  "ChangeBackgroundOptions.loading": "Chargement d'arrière-plans virtuels",
  "ChangeBackgroundOptions.none": "Désactiver les changements d'arrière-plan",
  "Chat.failed": "Échec de l'envoi",
  "Chat.i_say": "Je dis:",
  "Chat.send_message": "Envoyez un message à tout le monde",
  "Chat.sending_message": "Envoi du message",
  "Chat.someone_says": "{name} dit :",
  "Chat.title": "Discuter",
  "Chat.unread_messages": "Vous avez des messages non lus",
  "Chat.warn_about_phone_participants": "Les participants au téléphone ne peuvent pas lire les messages de chat",
  "ChatBot.find_real_person": "Salut {name} ! Je vais vous trouver une vraie personne avec qui discuter.",
  "ChatBot.invite_to_audio_call": "Moi encore! {name} vous a invité à participer à un appel audio : {url} (le lien s'ouvre dans un nouvel onglet)",
  "ChatBot.invite_to_video_call": "Moi encore! {name} vous a invité à participer à un appel vidéo : {url} (le lien s'ouvre dans un nouvel onglet)",
  "ChatBot.name": "Chatbot",
  "ChatDashboard.closed_chats_title": "Chats fermés",
  "ChatDashboard.my_chats": "Mes discussions ( {number} )",
  "ChatDashboard.open_chats_title": "Ouvrir les chats",
  "ChatDashboard.unassigned": "Non attribué ( {number} )",
  "ChatEvent.assigned": "Chat qui vous est attribué : {time}",
  "ChatEvent.closed": "{user} a fermé le chat : {time}",
  "ChatEvent.joined": "{user} a rejoint le chat : {time}",
  "ChatEvent.reopened": "{user} a rouvert le chat : {time}",
  "ChatEvent.started": "Le chat a commencé : {time}",
  "ChatWidget.email": "E-mail:",
  "ChatWidget.hi": "Salut!",
  "ChatWidget.maximize": "Agrandir la fenêtre",
  "ChatWidget.minimize": "Réduire la fenêtre",
  "ChatWidget.modal_title": "Discutez avec quelqu'un de {name}",
  "ChatWidget.send_message": "Envoyer un message",
  "ChatWidget.start": "Démarrer la discussion",
  "ChatWidget.title": "Discuter",
  "ChatWidget.your_name": "Votre nom:",
  "ChatWidgetActionsButton.stop_chat": "Arrêter le chat",
  "ChatWidgetActionsButton.stop_chat_description": "Voulez-vous vraiment arrêter le chat ?",
  "ChatWidgetActionsButton.stop_chat_title": "Arrêter le chat ?",
  "ChatWidgetActionsButton.yes_stop_chat": "Oui, arrêter le chat",
  "ClosedCaptionsConnectionStatus.cannot_connect": "Nous ne parvenons pas à nous connecter à notre service de sous-titrage.",
  "ClosedCaptionsConnectionStatus.connecting": "Connexion au service de sous-titrage.",
  "ClosedCaptionsConnectionStatus.reconnecting": "Notre service de sous-titrage ne répond pas. Nous essayons de nous reconnecter.",
  "CoBrowse.cancel": "Annuler",
  "CoBrowse.co_browse_webpage": "Co-naviguer sur une page web",
  "CoBrowse.connected_description": "La co-navigation commencera une fois que tout le monde sera connecté",
  "CoBrowse.empty": "Vous n'avez aucune page Web de co-navigation",
  "CoBrowse.in_progress_description": "Actuellement en co-navigation",
  "CoBrowse.in_use": "Quelqu'un d'autre partage l'écran",
  "CoBrowse.in_your_use": "Vous partagez déjà votre écran",
  "CoBrowse.loading_description": "S'assurer que la page peut être co-naviguée",
  "CoBrowse.select_page": "Sélectionnez une page à co-naviguer",
  "CoBrowse.socket_error": "Bloqué par des éléments tels que les bloqueurs de publicités, les bloqueurs de cookies ou les pare-feu",
  "CoBrowse.stop": "Arrêt",
  "CoBrowse.unsupported": "Cette URL ne prend pas en charge la co-navigation",
  "CoBrowse.warn_about_phone_participants": "Les participants par téléphone ne peuvent pas co-naviguer",
  "CoBrowseDialog.cancelled_description": "{name} a fermé votre session de co-navigation",
  "CoBrowseDialog.iframe_failed": "Une erreur s'est produite lors du chargement de la session par l'un des participants",
  "CoBrowseDialog.interrupted": "Co-navigation interrompue",
  "CoBrowseDialog.interrupted_description": "{name} a essayé de démarrer une session de co-navigation mais une erreur s'est produite",
  "CoBrowseDialog.stop_co_browsing": "Arrêtez la co-navigation",
  "CoBrowseDialog.try_again": "Essayer à nouveau",
  "CoBrowsingFrame.loading_page": "Page de chargement",
  "CoBrowsingFrame.participant_is_navigator": "{name} est maintenant le navigateur",
  "CoBrowsingFrame.unsupported": "Cette page ne prend pas en charge la co-navigation",
  "CoBrowsingFrame.you_are_navigator": "Vous êtes maintenant le navigateur",
  "CoBrowsingHeader.co_browsing": "Co-navigation",
  "CoBrowsingHeader.refresh": "Rafraîchir",
  "CoBrowsingHeader.session_failed_to_load": "Échec du chargement de la session de co-navigation. Vous pouvez essayer de le rafraîchir.",
  "CoBrowsingHeader.stop_co_browsing": "Arrêtez la co-navigation",
  "CoBrowsingHeader.take_over_as_navigator": "Prendre la relève en tant que navigateur",
  "CoBrowsingHeader.you_are_navigator": "Vous êtes le navigateur",
  "ContactSupport.description": "Si vous avez besoin de contacter l'assistance, incluez le fouillis de caractères suivant dans votre message. Il s'agit d'un identifiant unique du problème qui l'aidera à être diagnostiqué plus rapidement 🙂",
  "ContactSupport.title": "Obtenir de l'aide",
  "ConversationsDashboard.empty_description": "Envoyez des messages texte aux clients par SMS et conservez-les tous au même endroit, ici même",
  "ConversationsDashboard.new_conversation": "Nouvelle conversation",
  "ConversationsDashboard.start_conversation": "Commencer une conversation",
  "ConversationsDashboard.title": "Conversations",
  "Details.title": "Détails de la réunion",
  "DocumentIntegrationLogin.choose_platform": "Choisissez une plateforme de signature électronique",
  "DocumentIntegrationLogin.choose_platform_description": "Vos documents de signature électronique seront disponibles ici après votre connexion",
  "DocumentIntegrationLogin.connect": "Relier",
  "DocumentIntegrationLogin.continue": "Continuer avec ce compte",
  "DocumentIntegrationLogin.dialog_description": "Vous n'êtes pas connecté au compte DocuSign de votre organisation ( {account} ). Cela pourrait empêcher les signataires ou vous-même d'accéder aux documents et enveloppes de votre organisation pendant l'appel.",
  "DocumentIntegrationLogin.dialog_title": "Vous êtes-vous connecté avec le bon compte DocuSign ?",
  "DocumentIntegrationLogin.one_span_error_description": "Votre e-mail n'existe pas dans le compte OneSpan Sign de votre organisation",
  "DocumentIntegrationLogin.one_span_error_title": "La connexion à OneSpan Sign a échoué",
  "DocumentIntegrationLogin.use_different_account": "Se connecter avec un compte différent",
  "DocumentReview.back": "Retour à la critique",
  "DocumentReview.back_to_review": "Oui, retour à l'examen",
  "DocumentReview.back_to_review_description": "Voulez-vous vraiment quitter le processus de signature et revenir à l'examen maintenant ?",
  "DocumentReview.back_to_review_title": "Retour à la critique ?",
  "DocumentReview.cancelled_by": "Annulé par",
  "DocumentReview.cancelled_signing": "Signature annulée",
  "DocumentReview.close": "Quitter l'examen",
  "DocumentReview.close_document": "Fermer le document",
  "DocumentReview.close_signing": "Signature de sortie",
  "DocumentReview.currently_signing": "Signature en cours",
  "DocumentReview.declined_by": "Refusé par",
  "DocumentReview.document_already_signed": "La signature ne peut pas continuer car le document a déjà été signé en dehors de cet appel.",
  "DocumentReview.document_cannot_be_signed": "Ce document ne peut plus être signé",
  "DocumentReview.document_declined": "La signature ne peut pas continuer car certains signataires ont refusé de signer le document en dehors de cet appel.",
  "DocumentReview.document_expired": "La signature ne peut pas continuer car l'accès au document a expiré.",
  "DocumentReview.document_voided": "La signature ne peut pas continuer car le document a été annulé en dehors de cet appel.",
  "DocumentReview.done_signing": "Signature terminée",
  "DocumentReview.exit_signing": "Oui, signature de sortie",
  "DocumentReview.exit_signing_description": "Voulez-vous vraiment quitter le processus de signature maintenant ?",
  "DocumentReview.exit_signing_title": "Quitter la signature ?",
  "DocumentReview.failed_to_start_session": "Échec du démarrage de la session de signature",
  "DocumentReview.failed_to_start_session_description": "Une erreur s'est produite lors de la tentative de démarrage de la session de signature",
  "DocumentReview.in_progress": "Signature en cours",
  "DocumentReview.input": "Document",
  "DocumentReview.loading": "Chargement du document",
  "DocumentReview.match_signer": "Correspondance avec {name}",
  "DocumentReview.match_to": "Correspondre à",
  "DocumentReview.no_participants_to_match": "Aucun participant correspondant",
  "DocumentReview.resume": "Reprendre",
  "DocumentReview.signers_missing": "Certains signataires manquent",
  "DocumentReview.signers_missing_description": "Les adresses e-mail de certains signataires ne correspondent à personne sur cet appel. Faites correspondre l'e-mail avec quelqu'un sur l'appel ou continuez sans correspondance.",
  "DocumentReview.signers_missing_from_call": "Certains signataires sont absents de l'appel.",
  "DocumentReview.signing_cancelled_title": "Signature annulée",
  "DocumentReview.signing_cannot_continue": "Le processus de signature ne peut pas continuer.",
  "DocumentReview.signing_cannot_continue_local": "Les autres ne peuvent pas signer tant que vous n'avez pas repris et terminé.",
  "DocumentReview.signing_cannot_continue_until": "Le processus de signature ne peut pas continuer tant qu'il n'est pas terminé.",
  "DocumentReview.signing_ceremony": "Cérémonie de signature",
  "DocumentReview.signing_completed_title": "Signature terminée",
  "DocumentReview.signing_declined_title": "Signature refusée",
  "DocumentReview.signing_exception_description": "Une erreur système s'est produite lors de la signature de <strong> {envelope} </strong>",
  "DocumentReview.signing_exception_title": "Quelque chose s'est mal passé",
  "DocumentReview.signing_in_progress": "Signature en cours",
  "DocumentReview.signing_incomplete_description": "Certains signataires ont annulé, peut-être pour finir plus tard.",
  "DocumentReview.signing_incomplete_description_local": "Vous avez annulé le processus de signature.",
  "DocumentReview.signing_incomplete_title": "Signature incomplète",
  "DocumentReview.signing_title": "Signature : <strong> {name} </strong>",
  "DocumentReview.start_signing": "Commencez à signer",
  "DocumentReview.start_when_your_turn": "Votre session démarrera automatiquement lorsque ce sera votre tour",
  "DocumentReview.stay_in_signing": "Restez en signature",
  "DocumentReview.title": "Révision : <strong> {name} </strong>",
  "DocumentReview.verify_signers_identities": "Assurez-vous d'avoir vérifié l'identité des signataires avant de faire correspondre",
  "DocumentReview.waiting": "En attente de signature pour commencer",
  "DocumentReview.waiting_to_sign": "En attente de signature",
  "Documents.add": "Ajouter des documents de {provider}",
  "Documents.already_added": "Déjà ajouté",
  "Documents.connecting_to": "Connexion à {provider}",
  "Documents.declined": "Diminué",
  "Documents.declined_description": "Les documents refusés ne peuvent pas être signés",
  "Documents.disconnect": "Déconnecter",
  "Documents.empty": "Il n'y a pas de documents",
  "Documents.envelope_document_count": "{count, plural, one {# document} other {# documents}}",
  "Documents.envelope_documents": "Documents pour {envelope}",
  "Documents.envelopes": "Enveloppes sélectionnées",
  "Documents.expired": "Expiré",
  "Documents.expired_description": "Les documents expirés ne peuvent pas être signés",
  "Documents.hide_documents": "Masquer les documents",
  "Documents.loading": "Chargement de documents",
  "Documents.no_document": "Aucun document",
  "Documents.partially_signed": "Partiellement signé",
  "Documents.review_and_sign": "Révisez ensemble et signez",
  "Documents.review_together": "Réviser ensemble",
  "Documents.show_documents": "Afficher les documents",
  "Documents.signable_envelope_document_count": "{count, plural, one { <div> {state} </div> - # document } other { <div> {state} </div> - # documents }}",
  "Documents.signed": "Signé",
  "Documents.signed_description": "Déjà signé",
  "Documents.title": "Documents",
  "Documents.unsigned": "Non signé",
  "Documents.uploading_document": "Téléchargement du document",
  "Documents.voided": "Annulé",
  "Documents.voided_description": "Les documents annulés ne peuvent pas être signés",
  "Documents.warn_about_phone_participants": "Les participants par téléphone ne peuvent pas accéder aux documents",
  "EndCallButton.title": "Quitter l'appel",
  "Feedback.comment_help_text": "Vos commentaires sont anonymes",
  "Feedback.comment_label": "Qu'est-ce qui améliorerait votre expérience ?",
  "Feedback.dissatisfied": "Mécontent",
  "Feedback.neutral": "Neutre",
  "Feedback.satisfied": "Satisfait",
  "Feedback.send_feedback": "Envoyer des commentaires",
  "Feedback.thanks": "Merci pour vos commentaires!",
  "Feedback.title_chat": "Comment s'est passée votre expérience de chat ?",
  "Feedback.title_connect": "Comment s'est passée votre expérience d'appel vidéo ?",
  "ForgotPassword.description": "Entrez votre email et nous vous enverrons un lien pour réinitialiser votre mot de passe",
  "ForgotPassword.go_back": "Retour à la connexion",
  "ForgotPassword.loading": "Nous vous envoyons un e-mail",
  "ForgotPassword.success_description": "Nous vous avons envoyé un lien pour réinitialiser votre mot de passe",
  "ForgotPassword.success_title": "Vérifiez votre boîte de réception",
  "ForgotPassword.title": "Mot de passe oublié?",
  "InternalNotes.label": "Remarques",
  "InternalNotes.loading": "Chargement des notes",
  "InternalNotes.subtitle": "Les notes internes ne sont pas visibles pour les clients.",
  "InternalNotes.title": "Remarques internes",
  "Login.forgot_password": "Mot de passe oublié?",
  "Login.loading": "Connexion à Coconut",
  "Login.title": "Connectez-vous à la noix de coco",
  "Login.zoom_description": "Connectez-vous à Coconut pour vous connecter à Zoom",
  "MediaErrorAlert.GeneralError.title": "Erreur lors de l'acquisition du microphone ou de la caméra",
  "MediaErrorAlert.NoCameraError.description": "Aucune caméra détectée. Les autres participants présents dans la salle ne pourront pas vous voir.",
  "MediaErrorAlert.NoDeviceError.description": "Aucune caméra ou microphone détecté. Les autres participants ne pourront ni vous entendre ni vous voir.",
  "MediaErrorAlert.NoMicrophoneError.description": "Aucun micro détecté. Les autres participants présents dans la salle ne pourront pas vous entendre.",
  "MediaErrorAlert.NotAllowedError.description_denied_by_os": "Nous ne pouvons pas accéder à votre microphone ou à votre caméra en raison des paramètres du système d'exploitation. Veuillez vérifier les paramètres de votre système d'exploitation.",
  "MediaErrorAlert.NotAllowedError.description_denied_by_user": "Nous ne pouvons pas accéder à votre microphone ou à votre caméra en raison de problèmes d'autorisation",
  "MediaErrorAlert.NotFoundError.description": "Le navigateur ne peut pas accéder au microphone ou à la caméra. Veuillez vous assurer que les appareils sont connectés et activés.",
  "MediaErrorAlert.NotReadableError.description": "Le navigateur ne peut pas démarrer la capture multimédia. Veuillez fermer toutes les autres applications et onglets qui utilisent un microphone ou une caméra et actualisez la page.",
  "MediaErrorAlert.NotReadableError.description_audio": "Aucun micro détecté. Veuillez fermer toutes les autres applications et onglets qui utilisent le microphone et actualisez la page.",
  "MediaErrorAlert.NotReadableError.description_video": "Aucune caméra détectée. Veuillez fermer toutes les autres applications et onglets qui utilisent l'appareil photo et actualisez la page.",
  "MediaErrorAlert.learn_how_to_fix": "Apprenez comment résoudre ce problème",
  "MediaErrorSnackbar.details": "Des détails",
  "Meeting.attendees": "Participants",
  "Meeting.bitrate_threshold_description": "Vous pouvez rencontrer des problèmes de qualité audio/vidéo pendant votre appel.",
  "Meeting.call_access_blocked": "Votre accès à cet appel est bloqué",
  "Meeting.call_access_blocked_description": "Nous sommes désolés de vous laisser en plan. Votre accès à cet appel est actuellement bloqué par une configuration système telle qu'un pare-feu ou un proxy. Contactez votre support informatique pour obtenir de l'aide.",
  "Meeting.cancelled": "Cette réunion a été annulée",
  "Meeting.disconnected": "Vous avez quitté la réunion",
  "Meeting.ended": "Cette réunion est terminée",
  "Meeting.in_call": "En appel",
  "Meeting.is_appointment_done": "Votre rendez-vous est fait ?",
  "Meeting.join_now": "Adhérer maintenant",
  "Meeting.joining": "Rejoindre la réunion",
  "Meeting.joining_now": "Rejoindre maintenant",
  "Meeting.manage": "Gérer le rendez-vous",
  "Meeting.meeting_title": "Rencontre avec {name}",
  "Meeting.not_in_call": "Pas en appel",
  "Meeting.page_title": "Rejoindre une réunion",
  "Meeting.participant_cancelled": "Votre invitation a été annulée",
  "Meeting.preview_settings": "Aperçu de vos paramètres",
  "Meeting.recording": "L'audio de cette réunion est enregistré à des fins de qualité",
  "Meeting.rejoin": "Rejoindre",
  "Meeting.rejoining": "Rejoindre",
  "Meeting.removed_from_call": "Vous avez été retiré de l'appel",
  "Meeting.scheduled_for": "Cette réunion est prévue pour le <strong> {date} </strong>",
  "Meeting.something_went_wrong": "Quelque chose s'est mal passé",
  "Meeting.starting": "Début de la réunion",
  "Meeting.stranded": "Nous sommes désolés de vous laisser en plan. {br} Nous travaillons dur pour résoudre ce problème.",
  "Meeting.temporarily_unavailable": "Cet appel est temporairement indisponible",
  "Meeting.too_early": "Vous êtes un peu trop tôt",
  "Meeting.unable_to_connect": "Nous ne parvenons pas à nous connecter à votre réunion",
  "Meeting.update_appointment_status": "Génial! N'oubliez pas de mettre à jour le statut du rendez-vous",
  "MeetingDetails.add_notes": "Ajouter des remarques",
  "MeetingDetails.additional_details": "Détails supplémentaires",
  "MeetingDetails.attendees": "Participants",
  "MeetingDetails.booking_info": "Informations de réservation",
  "MeetingDetails.clients": "Clients ({count})",
  "MeetingDetails.edit_notes": "Éditer",
  "MeetingDetails.internal_notes": "Remarques internes",
  "MeetingDetails.loading": "Chargement des détails de la réunion",
  "MeetingDetails.many_participants_title": "{first} , {second} , + {count} plus - {service}",
  "MeetingDetails.no_answer": "Pas de réponse",
  "MeetingDetails.no_participants_title": "{service}",
  "MeetingDetails.private_question": "<span> Privé : </span> {label}",
  "MeetingDetails.questions": "Des questions",
  "MeetingDetails.single_participant_title": "{first} - {service}",
  "MeetingDetails.subtitle": "Aujourd'hui, {start} - {end} ({timezone})",
  "MeetingDetails.three_participants_title": "{first} , {second} , {third} - {service}",
  "MeetingDetails.two_participants_title": "{first} et {second} - {service}",
  "MeetingDetails.users": "Personnel ({count})",
  "NoConversationSelected.description": "Sélectionnez une conversation pour la visualiser ou démarrez-en une nouvelle",
  "ParticipantActions.camera_is_off": "Leur caméra est éteinte",
  "ParticipantActions.camera_off": "Appareil photo éteint",
  "ParticipantActions.cannot_mute_phone_participant": "Vous ne pouvez pas couper le son d'un participant par téléphone",
  "ParticipantActions.cannot_turn_on_camera": "Vous ne pouvez pas allumer la caméra de quelqu'un",
  "ParticipantActions.cannot_unmute": "Vous ne pouvez pas réactiver le son de quelqu'un",
  "ParticipantActions.mark_verified": "Marquer comme vérifié",
  "ParticipantActions.muted": "En sourdine",
  "ParticipantActions.only_organizer_can_remove": "Seul l'organisateur peut supprimer des personnes",
  "ParticipantActions.participant_verified": "{name} est vérifié",
  "ParticipantActions.take_screenshot": "Prendre une capture d'écran",
  "ParticipantActions.verified": "Vérifié",
  "ParticipantActions.verifying": "Vérification",
  "ParticipantInfo.avatar_label": "Avatar de {name}",
  "ParticipantInfo.avatar_local_label": "Avatar de {name} (vous)",
  "ParticipantInfo.email": "E-mail du participant",
  "ParticipantInfo.invite_another": "Invitez un autre participant ou partagez les informations de participation",
  "ParticipantInfo.invited_participant": "Envoyé à <b> {email} </b>",
  "ParticipantInfo.inviting_participant": "Envoi à <b> {email} </b>",
  "ParticipantInfo.joining_info": "Informations d'adhésion",
  "ParticipantInfo.local_name": "{name} (vous)",
  "ParticipantInfo.meeting_ready": "Votre réunion est prête",
  "ParticipantInfo.name_not_in_call": "{name} (pas en appel)",
  "ParticipantInfo.navigator_name": "{name} (navigateur)",
  "ParticipantInfo.send": "Inviter",
  "ParticipantInfo.you": "Vous",
  "Participants.participant_left_meeting": "{name} a quitté la réunion",
  "Participants.participant_removed_from_call": "{name} a été retiré de l'appel",
  "People.add_another": "Ajouter un autre e-mail",
  "People.cancel_invitation": "Annuler l'invitation",
  "People.in_call": "En appel",
  "People.invite": "Inviter",
  "People.invite_others": "Invitez d'autres personnes à vous rejoindre",
  "People.invited": "Invité",
  "People.joined_by_phone": "Joint par téléphone",
  "People.no_email": "L'invité n'a pas d'adresse e-mail",
  "People.no_show": "Pas de spectacle",
  "People.removal_is_permanent": "La suppression de personnes de l'appel ne peut pas être annulée",
  "People.remove_email": "Supprimer {email}",
  "People.remove_from_call": "Retirer de l'appel",
  "People.resend_invitation": "Renvoyer l'invitation",
  "People.send_invitations": "Envoyer des invitations",
  "People.unverified_identities": "Identités non vérifiées",
  "People.unverified_identities_description": "Certaines personnes de l'appel n'ont pas fait vérifier leur identité",
  "People.waiting": "En attente d'être admis",
  "Position.label": "{start} - {end} sur {total}",
  "Position.label_single_item": "{start} sur {total}",
  "PreChatSurvey.description": "Un vrai humain vivant (sérieusement) est là pour vous aider. Mais nous avons d'abord besoin de votre nom et de votre adresse e-mail pour savoir à qui nous parlons.",
  "PreChatSurvey.title": "Nous aimerions discuter",
  "PreJoinScreen.ask_to_join": "Demander à rejoindre",
  "PreJoinScreen.denied": "Votre participation à cet appel a été refusée",
  "PreJoinScreen.email_label": "Votre e-mail",
  "PreJoinScreen.forbidden_description": "Cette réunion est en cours avec tous les participants autorisés",
  "PreJoinScreen.forbidden_title": "Vous ne pouvez pas rejoindre cette réunion",
  "PreJoinScreen.join_by_phone": "Rejoindre par téléphone : <a> {number} </a>",
  "PreJoinScreen.join_by_phone_label": "Joindre par téléphone",
  "PreJoinScreen.join_by_phone_warn_organizer": "Vous ne pouvez pas participer par téléphone en tant qu'organisateur",
  "PreJoinScreen.name_label": "Nom et prénom",
  "PreJoinScreen.other_user_prompt": "Pas {name} ?",
  "PreJoinScreen.pin": "NIP : {number}",
  "PreJoinScreen.redirecting": "Vérifier si vous êtes connecté",
  "PreJoinScreen.sign_in": "S'identifier",
  "PreJoinScreen.sign_in_to_join": "Connectez-vous pour rejoindre",
  "PreJoinScreen.waiting": "En attendant que quelqu'un vous laisse entrer",
  "PreJoinScreen.work_at_tenant": "Vous travaillez chez {name} ?",
  "ResetPassword.description": "Saisissez un nouveau mot de passe pour :",
  "ResetPassword.failed_description": "Quelque chose ne va pas avec le lien que vous avez utilisé pour arriver ici. Essayez à nouveau le lien de votre e-mail.",
  "ResetPassword.failed_title": "Impossible de réinitialiser le mot de passe",
  "ResetPassword.loading": "Définition de votre nouveau mot de passe",
  "ResetPassword.password": "Nouveau mot de passe",
  "ResetPassword.password_helper_text": "Doit contenir au moins 10 caractères",
  "ResetPassword.show_password": "Montrer le mot de passe",
  "ResetPassword.success_title": "Votre mot de passe est défini",
  "ResetPassword.title": "Réinitialisez votre mot de passe",
  "Room.people_are_waiting": "Les gens attendent d'être admis à l'appel",
  "Room.recording": "Enregistrement audio",
  "Room.screen_being_shared": "{name} partage son écran",
  "Room.someone_was_invited": "{name} invité quelqu'un à l'appel",
  "Room.vortex": "Aider! je suis dans un tourbillon",
  "Room.you_are_sharing_screen": "Vous partagez votre écran",
  "RoomHeader.maximize": "Maximiser",
  "RoomHeader.minimize": "Minimiser",
  "RoomHeader.unstable_connection": "Votre connexion est instable",
  "RoomTimerDialog.are_you_still_there": "Vous êtes toujours là ?",
  "RoomTimerDialog.leave_call": "Quitter l'appel",
  "RoomTimerDialog.stay_in_call": "Restez dans l'appel",
  "RoomTimerDialog.this_call_will_end": "Vous êtes le seul ici. Cet appel se terminera dans moins de 2 minutes, sauf si vous choisissez de rester.",
  "Settings.camera_label": "Appareil photo",
  "Settings.microphone_label": "Microphone",
  "Settings.speakers_label": "Haut-parleurs",
  "Settings.title": "Réglages",
  "SocketErrorAlert.description": "Certaines fonctionnalités de collaboration sont bloquées par des éléments tels que les bloqueurs de publicités, les bloqueurs de cookies ou les pare-feu.",
  "SpecificChat.assign_to_me": "Attribuez-moi",
  "SpecificChat.assigned_to": "Ce chat a été attribué à {name}",
  "SpecificChat.close_and_archive": "Fermer et archiver",
  "SpecificChat.closed": "Ce chat est fermé",
  "SpecificChat.hide_profile": "Masquer les informations de profil",
  "SpecificChat.pre_chat_survey": "Sondage pré-chat",
  "SpecificChat.profile": "Profil",
  "SpecificChat.reopen": "Rouvrir",
  "SpecificChat.show_profile": "Afficher les informations de profil",
  "SpecificChat.start_audio_call": "Démarrer un appel audio",
  "SpecificChat.start_video_call": "Démarrer un appel vidéo",
  "SpecificChat.stopped": "Ce chat a été arrêté",
  "SpecificChat.unassigned": "Ce chat n'est pas attribué",
  "SpecificChat.viewer": "Vous êtes le seul à le voir",
  "SpecificChat.viewers": "Vous et {count} {count, plural, one { autre personne } other { autres personnes }} le visionnez actuellement",
  "SpecificChatActionsButton.close_chat": "Fermer le chat et archiver",
  "SpecificChatActionsButton.close_chat_description": "Voulez-vous vraiment fermer ce chat et l'archiver ?",
  "SpecificChatActionsButton.close_chat_title": "Fermer et archiver ?",
  "SpecificChatActionsButton.yes_close_chat": "Oui, fermer et archiver",
  "SpecificChatViewers.more": "+ {count} plus",
  "ToggleAppointmentDetailsButton.close": "Fermer les détails de la réunion",
  "ToggleAppointmentDetailsButton.details": "Détails de la réunion",
  "ToggleAudioButton.mute": "Muet",
  "ToggleAudioButton.unmute": "Rétablir le son",
  "ToggleCaptionsButton.off": "Désactiver les sous-titres",
  "ToggleCaptionsButton.on": "Activer les sous-titres",
  "ToggleChangeBackgroundButton.close": "Fermer Modifier l'arrière-plan",
  "ToggleChangeBackgroundButton.open": "Changement de fond",
  "ToggleChangeBackgroundButton.unavailable_with_this_browser": "Indisponible avec ce navigateur",
  "ToggleChatButton.close": "Fermer la discussion",
  "ToggleChatButton.open": "Discuter",
  "ToggleCoBrowseButton.close_co_browse": "Fermer Co-naviguer",
  "ToggleDocumentsButton.close": "Fermer les documents",
  "ToggleDocumentsButton.documents": "Documents",
  "ToggleInternalNotesButton.close": "Fermer les notes internes",
  "ToggleInternalNotesButton.notes": "Remarques internes",
  "ToggleMoreButton.more": "Suite",
  "TogglePeopleButton.close": "Personnes proches",
  "TogglePeopleButton.open": "Gens",
  "ToggleScreenShareButton.co_browse_in_use": "La co-navigation est active",
  "ToggleScreenShareButton.in_use": "Quelqu'un d'autre partage l'écran",
  "ToggleScreenShareButton.share": "Partager l'écran",
  "ToggleScreenShareButton.share_with_phone_participants": "Partager l'écran (non visible pour les participants par téléphone)",
  "ToggleScreenShareButton.stop_sharing": "Arrêter de partager",
  "ToggleScreenShareButton.unavailable_on_device": "Non disponible sur cet appareil",
  "ToggleScreenShareButton.warn_about_phone_participants": "Les participants au téléphone ne peuvent pas voir les écrans partagés",
  "ToggleSettingsButton.close_settings": "Fermer les paramètres",
  "ToggleSettingsButton.settings": "Réglages",
  "ToggleVideoButton.off": "Éteindre la caméra",
  "ToggleVideoButton.on": "Allumer la caméra",
  "TwilioComponentAlert.degraded_audio_description": "Vous pouvez rencontrer des perturbations si vous vous inscrivez par téléphone. Nous travaillons pour résoudre ce problème.",
  "TwilioComponentAlert.degraded_audio_title": "L'inscription par téléphone pose des problèmes",
  "TwilioComponentAlert.degraded_video_description": "Il se peut que vous rencontriez des perturbations dans votre vidéo pendant l'appel. Nous travaillons pour résoudre ce problème.",
  "TwilioComponentAlert.degraded_video_title": "L'affichage vidéo a des problèmes",
  "TwilioComponentAlert.unavailable_audio_description": "Vous pouvez toujours rejoindre cet appel par vidéo. Nous travaillons pour résoudre ce problème.",
  "TwilioComponentAlert.unavailable_audio_title": "L'inscription par téléphone est actuellement indisponible",
  "TwilioComponentAlert.unavailable_video_description": "Vous pouvez toujours rejoindre cet appel en utilisant uniquement l'audio. Nous travaillons pour résoudre ce problème.",
  "TwilioComponentAlert.unavailable_video_title": "La vidéo est actuellement indisponible",
  "TwilioComponentAlert.under_maintenance_audio_description": "Vous pouvez toujours rejoindre cet appel par vidéo.",
  "TwilioComponentAlert.under_maintenance_audio_title": "L'adhésion par téléphone est actuellement en maintenance",
  "TwilioComponentAlert.under_maintenance_video_description": "Vous pouvez toujours rejoindre cet appel en utilisant uniquement l'audio.",
  "TwilioComponentAlert.under_maintenance_video_title": "La vidéo est actuellement en maintenance",
  "Ui.admit": "Admettre",
  "Ui.close": "Proche",
  "Ui.closed": "Fermé",
  "Ui.continue": "Continuer",
  "Ui.copied": "Copié !",
  "Ui.copy": "Copie",
  "Ui.count_more": "+ {count} plus",
  "Ui.deny": "Refuser",
  "Ui.details": "Des détails",
  "Ui.dismiss": "Rejeter",
  "Ui.download": "Télécharger",
  "Ui.email": "E-mail",
  "Ui.go_back": "Retourner",
  "Ui.got_it": "J'ai compris",
  "Ui.hide_people": "Masquer les personnes",
  "Ui.loading": "Chargement...",
  "Ui.more": "Suite",
  "Ui.next": "Suivant",
  "Ui.open": "Ouvert",
  "Ui.other": "Autre",
  "Ui.password": "Mot de passe",
  "Ui.refresh": "Rafraîchir",
  "Ui.refresh_contents": "Actualiser le contenu",
  "Ui.refreshed": "{name} est actualisé",
  "Ui.refreshing": "Rafraîchissant",
  "Ui.remove": "Retirer",
  "Ui.resend": "Renvoyer",
  "Ui.send": "Envoyer",
  "Ui.show_less": "Montrer moins",
  "Ui.show_more": "Montre plus",
  "Ui.show_people": "Afficher les personnes",
  "Ui.sign_in": "S'identifier",
  "Ui.sign_in_failed_email_description": "L'e-mail et/ou le mot de passe étaient incorrects",
  "Ui.sign_in_failed_title": "Impossible de vous connecter",
  "Ui.sign_in_failed_username_description": "Le nom d'utilisateur et/ou le mot de passe étaient incorrects",
  "Ui.try_again": "Réessayer",
  "Ui.upload": "Télécharger",
  "Ui.username_or_email": "Nom d'utilisateur ou email",
  "Ui.your_name": "Votre nom",
  "UnsupportedBrowser.browser_suggestion": "Veuillez réessayer en utilisant un navigateur plus récent tel que Chrome ou Firefox.",
  "UnsupportedBrowser.browser_suggestion_android": "Veuillez réessayer avec une version plus récente de Chrome ou de Firefox.",
  "UnsupportedBrowser.browser_suggestion_ios": "Veuillez réessayer avec une version plus récente de Safari.",
  "UnsupportedBrowser.browser_suggestion_mac": "Veuillez réessayer avec une version plus récente de Chrome, Firefox, Safari ou Edge.",
  "UnsupportedBrowser.browser_suggestion_windows": "Veuillez réessayer avec une version plus récente de Chrome, Firefox ou Edge.",
  "UnsupportedBrowser.browser_suggestion_windows_phone": "Veuillez réessayer avec une version plus récente d'Edge.",
  "UnsupportedBrowser.description": "Nous ne pouvons pas vous connecter car votre navigateur Web ne prend pas en charge ce type d'appel vidéo.",
  "UnsupportedBrowser.title": "Votre navigateur n'est pas pris en charge",
  "Validation.email_required": "Le champ email est obligatoire",
  "Validation.email_valid": "L'e-mail doit être une adresse e-mail valide",
  "Validation.emails_required": "Ajoutez au moins une adresse e-mail",
  "Validation.emails_unique": "L'e-mail a déjà été fourni",
  "Validation.full_name_required": "Le champ nom complet est obligatoire",
  "Validation.name_required": "Il est requis de compléter le champ correspondant au nom",
  "Validation.password_length": "Doit contenir au moins 10 caractères",
  "Validation.password_required": "Le champ mot de passe est obligatoire",
  "Validation.url_required": "Le champ URL est obligatoire",
  "Validation.url_valid": "L'URL doit être une URL valide",
  "Validation.username_or_email_required": "Le champ nom d'utilisateur ou email est obligatoire",
  "VideoDegradedHeader.degraded": "La vidéo rencontre actuellement des problèmes. Nous travaillons pour résoudre ce problème.",
  "VideoDegradedHeader.unavailable": "La vidéo est actuellement indisponible. Nous travaillons pour résoudre ce problème.",
  "VideoTrack.stop_sharing": "Arrêter de partager",
  "VideoTrack.you_are_sharing": "Vous partagez votre écran",
  "VortexDialog.ok": "J'ai compris",
  "VortexDialog.stop_sharing": "Arrêter de partager",
  "VortexDialog.title": "Effet tourbillon ?",
  "VortexDialog.vortex_description": "Si vous rencontrez un miroir à l'infini sur votre écran, assurez-vous que vous ne partagez pas l'écran, la fenêtre ou l'onglet contenant l'appel vidéo. Essayez plutôt de ne partager qu'un onglet ou une autre fenêtre."
}
