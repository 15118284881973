export default {
  "ActionResult.background_blurred": "Background is now blurred",
  "ActionResult.background_not_blurred": "Background is no longer blurred",
  "ActionResult.background_not_replaced": "Background is no longer replaced",
  "ActionResult.background_replaced": "Background is now replaced",
  "ActionResult.background_slightly_blurred": "Background is now slightly blurred",
  "ActionResult.camera_off": "Your camera is off",
  "ActionResult.camera_on": "Your camera is on",
  "ActionResult.invitation_sent": "Invitation sent",
  "ActionResult.microphone_off": "Your microphone is off",
  "ActionResult.microphone_on": "Your microphone is on",
  "ActionResult.participant_admitted": "{name} is admitted",
  "ActionResult.participant_denied": "{name} is denied",
  "BookingInfo.api": "Booked through the API",
  "BookingInfo.call_centre": "Booked by {booker} through the Call Centre",
  "BookingInfo.kiosk": "Booked by {booker} through the Kiosk",
  "BookingInfo.lobby": "Booked by {booker} through the Lobby",
  "BookingInfo.modern_client_view": "Booked by {booker} through the Modern Client View",
  "BookingInfo.online_queue": "Booked by {booker} through the Online Queue",
  "BookingInfo.outlook_add_in": "Booked by {booker} through the Outlook Add In",
  "BookingInfo.schedule": "Booked by {booker} through the Schedule",
  "BookingInfo.tv": "Booked by {booker} through the TV",
  "BookingInfo.unknown": "Not available",
  "ChangeBackgroundOptions.blur": "Blur your background",
  "ChangeBackgroundOptions.blur_slightly": "Slightly blur your background",
  "ChangeBackgroundOptions.custom_background": "Custom Background {number}",
  "ChangeBackgroundOptions.custom_background_name": "Replace background with {name}",
  "ChangeBackgroundOptions.loading": "Loading virtual backgrounds",
  "ChangeBackgroundOptions.none": "Turn off background changes",
  "Chat.failed": "Failed to send",
  "Chat.i_say": "I say:",
  "Chat.send_message": "Send a message to everyone",
  "Chat.sending_message": "Sending message",
  "Chat.someone_says": "{name} says:",
  "Chat.title": "Chat",
  "Chat.unread_messages": "You have unread messages",
  "Chat.warn_about_phone_participants": "Phone participants can't read chat messages",
  "ChatBot.find_real_person": "Hi {name}! I'm going to find you a real person to chat with.",
  "ChatBot.invite_to_audio_call": "Me again! {name} has invited you to join an audio call: {url} (link opens in a new tab)",
  "ChatBot.invite_to_video_call": "Me again! {name} has invited you to join a video call: {url} (link opens in a new tab)",
  "ChatBot.name": "Chat Bot",
  "ChatDashboard.closed_chats_title": "Closed chats",
  "ChatDashboard.my_chats": "My Chats ({number})",
  "ChatDashboard.open_chats_title": "Open chats",
  "ChatDashboard.unassigned": "Unassigned ({number})",
  "ChatEvent.assigned": "Chat assigned to you: {time}",
  "ChatEvent.closed": "{user} closed the chat: {time}",
  "ChatEvent.joined": "{user} joined the chat: {time}",
  "ChatEvent.reopened": "{user} reopened the chat: {time}",
  "ChatEvent.started": "Chat started: {time}",
  "ChatWidget.email": "Email:",
  "ChatWidget.hi": "Hi!",
  "ChatWidget.maximize": "Maximize window",
  "ChatWidget.minimize": "Minimize window",
  "ChatWidget.modal_title": "Chat with someone from {name}",
  "ChatWidget.send_message": "Send a message",
  "ChatWidget.start": "Start chat",
  "ChatWidget.title": "Chat",
  "ChatWidget.your_name": "Your name:",
  "ChatWidgetActionsButton.stop_chat": "Stop chat",
  "ChatWidgetActionsButton.stop_chat_description": "Are you sure you want to stop the chat?",
  "ChatWidgetActionsButton.stop_chat_title": "Stop chat?",
  "ChatWidgetActionsButton.yes_stop_chat": "Yes, stop chat",
  "ClosedCaptionsConnectionStatus.cannot_connect": "We're unable to connect to our closed captioning service.",
  "ClosedCaptionsConnectionStatus.connecting": "Connecting to closed captioning service.",
  "ClosedCaptionsConnectionStatus.reconnecting": "Our closed captioning service is not responding. We're trying to reconnect.",
  "CoBrowse.cancel": "Cancel",
  "CoBrowse.co_browse_webpage": "Co-browse a web page",
  "CoBrowse.connected_description": "Co-browsing will start once everyone has connected",
  "CoBrowse.empty": "You don't have any co-browse web pages",
  "CoBrowse.in_progress_description": "Currently co-browsing",
  "CoBrowse.in_use": "Someone else is screen sharing",
  "CoBrowse.in_your_use": "You are already screen sharing",
  "CoBrowse.loading_description": "Making sure the page can be co-browsed",
  "CoBrowse.select_page": "Select a page to co-browse",
  "CoBrowse.socket_error": "Blocked by things like ad blockers, cookie blockers, or firewalls",
  "CoBrowse.stop": "Stop",
  "CoBrowse.unsupported": "This URL does not support co-browsing",
  "CoBrowse.warn_about_phone_participants": "Phone participants can't co-browse",
  "CoBrowseDialog.cancelled_description": "{name} has closed your co-browsing session",
  "CoBrowseDialog.iframe_failed": "An error occurred while loading the session by one of the participants",
  "CoBrowseDialog.interrupted": "Co-browsing interrupted",
  "CoBrowseDialog.interrupted_description": "{name} tried to start a co-browsing session but an error occurred",
  "CoBrowseDialog.stop_co_browsing": "Stop co-browsing",
  "CoBrowseDialog.try_again": "Try again",
  "CoBrowsingFrame.loading_page": "Loading page",
  "CoBrowsingFrame.participant_is_navigator": "{name} is now the navigator",
  "CoBrowsingFrame.unsupported": "This page doesn't support co-browsing",
  "CoBrowsingFrame.you_are_navigator": "You are now the navigator",
  "CoBrowsingHeader.co_browsing": "Co-browsing",
  "CoBrowsingHeader.refresh": "Refresh",
  "CoBrowsingHeader.session_failed_to_load": "Co-browsing session failed to load. You can try to refresh it.",
  "CoBrowsingHeader.stop_co_browsing": "Stop co-browsing",
  "CoBrowsingHeader.take_over_as_navigator": "Take over as navigator",
  "CoBrowsingHeader.you_are_navigator": "You are the navigator",
  "ContactSupport.description": "If you need to contact Support, include the following mess of characters with your message—it’s a unique identifier of the problem that will help it get diagnosed more quickly 🙂",
  "ContactSupport.title": "Get help",
  "ConversationsDashboard.empty_description": "Send text messages to clients via SMS and keep them all in one place, right here",
  "ConversationsDashboard.new_conversation": "New conversation",
  "ConversationsDashboard.start_conversation": "Start a conversation",
  "ConversationsDashboard.title": "Conversations",
  "Details.title": "Meeting Details",
  "DocumentIntegrationLogin.choose_platform": "Choose an e-signature platform",
  "DocumentIntegrationLogin.choose_platform_description": "Your e-signature documents will be available here after you connect",
  "DocumentIntegrationLogin.connect": "Connect",
  "DocumentIntegrationLogin.continue": "Continue with this account",
  "DocumentIntegrationLogin.dialog_description": "You're not signed in to your organization's DocuSign account ({account}). This could result in you or signers not being able to access your organization’s documents and envelopes during the call.",
  "DocumentIntegrationLogin.dialog_title": "Did you sign in using the right DocuSign account?",
  "DocumentIntegrationLogin.one_span_error_description": "Your email doesn't exist in your organization's OneSpan Sign account",
  "DocumentIntegrationLogin.one_span_error_title": "Connection to OneSpan Sign failed",
  "DocumentIntegrationLogin.use_different_account": "Sign in with a different account",
  "DocumentReview.back": "Back to review",
  "DocumentReview.back_to_review": "Yes, back to review",
  "DocumentReview.back_to_review_description": "Are you sure you want to exit the signing process anf go back to the review now?",
  "DocumentReview.back_to_review_title": "Back to review?",
  "DocumentReview.cancelled_by": "Cancelled by",
  "DocumentReview.cancelled_signing": "Cancelled signing",
  "DocumentReview.close": "Exit review",
  "DocumentReview.close_document": "Close document",
  "DocumentReview.close_signing": "Exit signing",
  "DocumentReview.currently_signing": "Currently signing",
  "DocumentReview.declined_by": "Declined by",
  "DocumentReview.document_already_signed": "Signing cannot continue because the document has already been signed outside of this call.",
  "DocumentReview.document_cannot_be_signed": "This document can no longer be signed",
  "DocumentReview.document_declined": "Signing cannot continue because some signers have declined to sign the document outside of this call.",
  "DocumentReview.document_expired": "Signing cannot continue because access to the document has expired.",
  "DocumentReview.document_voided": "Signing cannot continue because the document has been voided outside of this call.",
  "DocumentReview.done_signing": "Done signing",
  "DocumentReview.exit_signing": "Yes, exit signing",
  "DocumentReview.exit_signing_description": "Are you sure you want to exit the signing process now?",
  "DocumentReview.exit_signing_title": "Exit signing?",
  "DocumentReview.failed_to_start_session": "Failed to start signing session",
  "DocumentReview.failed_to_start_session_description": "An error has occured while trying to start the signing session",
  "DocumentReview.in_progress": "Signing in progress",
  "DocumentReview.input": "Document",
  "DocumentReview.loading": "Loading document",
  "DocumentReview.match_signer": "Match {name}",
  "DocumentReview.match_to": "Match to",
  "DocumentReview.no_participants_to_match": "No participants to match",
  "DocumentReview.resume": "Resume",
  "DocumentReview.signers_missing": "Some signers are missing",
  "DocumentReview.signers_missing_description": "The email addresses of some signers don't match anyone on this call. Match the email with someone on the call or continue without matching.",
  "DocumentReview.signers_missing_from_call": "Some signers are missing from the call.",
  "DocumentReview.signing_cancelled_title": "Signing cancelled",
  "DocumentReview.signing_cannot_continue": "The signing process cannot continue.",
  "DocumentReview.signing_cannot_continue_local": "Others cannot sign until you resume and finish.",
  "DocumentReview.signing_cannot_continue_until": "The signing process cannot continue until they finish.",
  "DocumentReview.signing_ceremony": "Signing ceremony",
  "DocumentReview.signing_completed_title": "Signing complete",
  "DocumentReview.signing_declined_title": "Signing declined",
  "DocumentReview.signing_exception_description": "A system error occurred during the signing of <strong>{envelope}</strong>",
  "DocumentReview.signing_exception_title": "Something went wrong",
  "DocumentReview.signing_in_progress": "Signing in progress",
  "DocumentReview.signing_incomplete_description": "Some signers cancelled, possibly to finish later.",
  "DocumentReview.signing_incomplete_description_local": "You cancelled the signing process.",
  "DocumentReview.signing_incomplete_title": "Signing incomplete",
  "DocumentReview.signing_title": "Signing: <strong>{name}</strong>",
  "DocumentReview.start_signing": "Start signing",
  "DocumentReview.start_when_your_turn": "Your session will start automatically when it's your turn",
  "DocumentReview.stay_in_signing": "Stay in signing",
  "DocumentReview.title": "Review: <strong>{name}</strong>",
  "DocumentReview.verify_signers_identities": "Ensure you have verified the signers' identities before matching",
  "DocumentReview.waiting": "Waiting for signing to start",
  "DocumentReview.waiting_to_sign": "Waiting to sign",
  "Documents.add": "Add documents from {provider}",
  "Documents.already_added": "Already added",
  "Documents.connecting_to": "Connecting to {provider}",
  "Documents.declined": "Declined",
  "Documents.declined_description": "Declined documents can't be signed",
  "Documents.disconnect": "Disconnect",
  "Documents.empty": "There are no documents",
  "Documents.envelope_document_count": "{count, plural, one {# document} other {# documents}}",
  "Documents.envelope_documents": "Documents for {envelope}",
  "Documents.envelopes": "Selected envelopes",
  "Documents.expired": "Expired",
  "Documents.expired_description": "Expired documents can't be signed",
  "Documents.hide_documents": "Hide documents",
  "Documents.loading": "Loading documents",
  "Documents.no_document": "No document",
  "Documents.partially_signed": "Partially signed",
  "Documents.review_and_sign": "Review together and sign",
  "Documents.review_together": "Review together",
  "Documents.show_documents": "Show documents",
  "Documents.signable_envelope_document_count": "{count, plural, one {<div>{state}</div> - # document} other {<div>{state}</div> - # documents}}",
  "Documents.signed": "Signed",
  "Documents.signed_description": "Already signed",
  "Documents.title": "Documents",
  "Documents.unsigned": "Unsigned",
  "Documents.uploading_document": "Uploading the document",
  "Documents.voided": "Voided",
  "Documents.voided_description": "Voided documents can't be signed",
  "Documents.warn_about_phone_participants": "Phone participants can't access documents",
  "EndCallButton.title": "Leave call",
  "Feedback.comment_help_text": "Your feedback is anonymous",
  "Feedback.comment_label": "What would improve your experience?",
  "Feedback.dissatisfied": "Dissatisfied",
  "Feedback.neutral": "Neutral",
  "Feedback.satisfied": "Satisfied",
  "Feedback.send_feedback": "Send feedback",
  "Feedback.thanks": "Thanks for your feedback!",
  "Feedback.title_chat": "How was your chat experience?",
  "Feedback.title_connect": "How was your video call experience?",
  "ForgotPassword.description": "Enter your email and we'll send you a link to reset your password",
  "ForgotPassword.go_back": "Back to sign in",
  "ForgotPassword.loading": "We're sending you an email",
  "ForgotPassword.success_description": "We sent you a link to reset your password",
  "ForgotPassword.success_title": "Check your inbox",
  "ForgotPassword.title": "Forgot your password?",
  "InternalNotes.label": "Notes",
  "InternalNotes.loading": "Loading notes",
  "InternalNotes.subtitle": "Internal Notes are not visible to clients.",
  "InternalNotes.title": "Internal Notes",
  "Login.forgot_password": "Forgot Password?",
  "Login.loading": "Signing in to Coconut",
  "Login.title": "Sign in to Coconut",
  "Login.zoom_description": "Sign in to Coconut to connect to Zoom",
  "MediaErrorAlert.GeneralError.title": "Error acquiring microphone or camera",
  "MediaErrorAlert.NoCameraError.description": "No camera detected. Other participants in the room will be unable to see you.",
  "MediaErrorAlert.NoDeviceError.description": "No camera or microphone detected. Other participants won’t be able to hear or see you.",
  "MediaErrorAlert.NoMicrophoneError.description": "No microphone detected. Other participants in the room will be unable to hear you.",
  "MediaErrorAlert.NotAllowedError.description_denied_by_os": "We can’t access your microphone or camera due to operating system settings. Please check your operating system settings.",
  "MediaErrorAlert.NotAllowedError.description_denied_by_user": "We can't access your microphone or camera due to permission issues",
  "MediaErrorAlert.NotFoundError.description": "The browser cannot access the microphone or camera. Please make sure devices are connected and enabled.",
  "MediaErrorAlert.NotReadableError.description": "The browser cannot start media capture. Please close all other apps and tabs that are using microphone or camera and refresh the page.",
  "MediaErrorAlert.NotReadableError.description_audio": "No microphone detected. Please close all other apps and tabs that are using the microphone and refresh the page.",
  "MediaErrorAlert.NotReadableError.description_video": "No camera detected. Please close all other apps and tabs that are using the camera and refresh the page.",
  "MediaErrorAlert.learn_how_to_fix": "Learn how to fix this",
  "MediaErrorSnackbar.details": "Details",
  "Meeting.attendees": "Attendees",
  "Meeting.bitrate_threshold_description": "You may experience issues with audio/video quality during your call.",
  "Meeting.call_access_blocked": "Your access to this call is blocked",
  "Meeting.call_access_blocked_description": "We're sorry to leave you stranded. Your access to this call is currently being blocked by a system configuration like a firewall or proxy. Contact your IT support for help.",
  "Meeting.cancelled": "This meeting has been cancelled",
  "Meeting.disconnected": "You left the meeting",
  "Meeting.ended": "This meeting has ended",
  "Meeting.in_call": "In call",
  "Meeting.is_appointment_done": "Is your appointment done?",
  "Meeting.join_now": "Join now",
  "Meeting.joining": "Joining meeting",
  "Meeting.joining_now": "Joining now",
  "Meeting.manage": "Manage appointment",
  "Meeting.meeting_title": "Meeting with {name}",
  "Meeting.not_in_call": "Not in call",
  "Meeting.page_title": "Join a Meeting",
  "Meeting.participant_cancelled": "Your invitation was cancelled",
  "Meeting.preview_settings": "Preview of your settings",
  "Meeting.recording": "The audio from this meeting is being recorded for quality purposes",
  "Meeting.rejoin": "Rejoin",
  "Meeting.rejoining": "Rejoining",
  "Meeting.removed_from_call": "You were removed from the call",
  "Meeting.scheduled_for": "This meeting is scheduled for <strong>{date}</strong>",
  "Meeting.something_went_wrong": "Something went wrong",
  "Meeting.starting": "Starting meeting",
  "Meeting.stranded": "We're sorry to leave you stranded.{br}We're working hard to fix this issue.",
  "Meeting.temporarily_unavailable": "This call is temporarily unavailable",
  "Meeting.too_early": "You're a bit too early",
  "Meeting.unable_to_connect": "We're unable to connect to your meeting",
  "Meeting.update_appointment_status": "Great! Don't forget to update the appointment status",
  "MeetingDetails.add_notes": "Add notes",
  "MeetingDetails.additional_details": "Additional Details",
  "MeetingDetails.attendees": "Attendees",
  "MeetingDetails.booking_info": "Booking Info",
  "MeetingDetails.clients": "Clients ({count})",
  "MeetingDetails.edit_notes": "Edit",
  "MeetingDetails.internal_notes": "Internal Notes",
  "MeetingDetails.loading": "Loading meeting details",
  "MeetingDetails.many_participants_title": "{first}, {second}, +{count} more - {service}",
  "MeetingDetails.no_answer": "No answer",
  "MeetingDetails.no_participants_title": "{service}",
  "MeetingDetails.private_question": "<span>Private:</span> {label}",
  "MeetingDetails.questions": "Questions",
  "MeetingDetails.single_participant_title": "{first} - {service}",
  "MeetingDetails.subtitle": "Today, {start} - {end} ({timezone})",
  "MeetingDetails.three_participants_title": "{first}, {second}, {third} - {service}",
  "MeetingDetails.two_participants_title": "{first} and {second} - {service}",
  "MeetingDetails.users": "Staff ({count})",
  "NoConversationSelected.description": "Select a conversation to view it, or start a new one",
  "ParticipantActions.camera_is_off": "Their camera is off",
  "ParticipantActions.camera_off": "Camera off",
  "ParticipantActions.cannot_mute_phone_participant": "You can't mute a phone participant",
  "ParticipantActions.cannot_turn_on_camera": "You can't turn on someone's camera",
  "ParticipantActions.cannot_unmute": "You can't unmute someone",
  "ParticipantActions.mark_verified": "Mark as Verified",
  "ParticipantActions.muted": "Muted",
  "ParticipantActions.only_organizer_can_remove": "Only the Organizer can remove people",
  "ParticipantActions.participant_verified": "{name} is verified",
  "ParticipantActions.take_screenshot": "Take screenshot",
  "ParticipantActions.verified": "Verified",
  "ParticipantActions.verifying": "Verifying",
  "ParticipantInfo.avatar_label": "Avatar of {name}",
  "ParticipantInfo.avatar_local_label": "Avatar of {name} (You)",
  "ParticipantInfo.email": "Attendee email",
  "ParticipantInfo.invite_another": "Invite another attendee or share the joining info",
  "ParticipantInfo.invited_participant": "Sent to <b>{email}</b>",
  "ParticipantInfo.inviting_participant": "Sending to <b>{email}</b>",
  "ParticipantInfo.joining_info": "Joining info",
  "ParticipantInfo.local_name": "{name} (You)",
  "ParticipantInfo.meeting_ready": "Your meeting's ready",
  "ParticipantInfo.name_not_in_call": "{name} (not in call)",
  "ParticipantInfo.navigator_name": "{name} (navigator)",
  "ParticipantInfo.send": "Invite",
  "ParticipantInfo.you": "You",
  "Participants.participant_left_meeting": "{name} has left the meeting",
  "Participants.participant_removed_from_call": "{name} was removed from the call",
  "People.add_another": "Add another email",
  "People.cancel_invitation": "Cancel invitation",
  "People.in_call": "In call",
  "People.invite": "Invite",
  "People.invite_others": "Invite others to join",
  "People.invited": "Invited",
  "People.joined_by_phone": "Joined by phone",
  "People.no_email": "Invitee has no email address",
  "People.no_show": "No show",
  "People.removal_is_permanent": "Removing people from the call cannot be undone",
  "People.remove_email": "Remove {email}",
  "People.remove_from_call": "Remove from the call",
  "People.resend_invitation": "Resend invitation",
  "People.send_invitations": "Send invitations",
  "People.unverified_identities": "Unverified identities",
  "People.unverified_identities_description": "Some people in the call have not had their identities verified",
  "People.waiting": "Waiting to be admitted",
  "Position.label": "{start}-{end} of {total}",
  "Position.label_single_item": "{start} of {total}",
  "PreChatSurvey.description": "A real live human (seriously) is here to help you. But first we need your name and email so we know who we're talking to.",
  "PreChatSurvey.title": "We'd love to chat",
  "PreJoinScreen.ask_to_join": "Ask to join",
  "PreJoinScreen.denied": "Your entry to this call was denied",
  "PreJoinScreen.email_label": "Your Email",
  "PreJoinScreen.forbidden_description": "This meeting is in progress with all permitted attendees",
  "PreJoinScreen.forbidden_title": "You cannot join this meeting",
  "PreJoinScreen.join_by_phone": "Join by phone: <a>{number}</a>",
  "PreJoinScreen.join_by_phone_label": "Join by phone",
  "PreJoinScreen.join_by_phone_warn_organizer": "You can't join by phone as the organizer",
  "PreJoinScreen.name_label": "Full Name",
  "PreJoinScreen.other_user_prompt": "Not {name}?",
  "PreJoinScreen.pin": "PIN: {number}",
  "PreJoinScreen.redirecting": "Checking if you're signed in",
  "PreJoinScreen.sign_in": "Sign in",
  "PreJoinScreen.sign_in_to_join": "Sign in to join",
  "PreJoinScreen.waiting": "Waiting for someone to let you in",
  "PreJoinScreen.work_at_tenant": "Work at {name}?",
  "ResetPassword.description": "Enter a new password for:",
  "ResetPassword.failed_description": "Something is wrong with the link you used to get here. Try the link from your email again.",
  "ResetPassword.failed_title": "Can't reset password",
  "ResetPassword.loading": "Setting your new password",
  "ResetPassword.password": "New Password",
  "ResetPassword.password_helper_text": "Must be at least 10 characters long",
  "ResetPassword.show_password": "Show password",
  "ResetPassword.success_title": "Your password is set",
  "ResetPassword.title": "Reset your password",
  "Room.people_are_waiting": "People are waiting to be admitted to the call",
  "Room.recording": "Recording audio",
  "Room.screen_being_shared": "{name} is sharing their screen",
  "Room.someone_was_invited": "{name} invited someone to the call",
  "Room.vortex": "Help! I’m in a vortex",
  "Room.you_are_sharing_screen": "You're sharing your screen",
  "RoomHeader.maximize": "Maximize",
  "RoomHeader.minimize": "Minimize",
  "RoomHeader.unstable_connection": "Your connection is unstable",
  "RoomTimerDialog.are_you_still_there": "Are you still there?",
  "RoomTimerDialog.leave_call": "Leave call",
  "RoomTimerDialog.stay_in_call": "Stay in the call",
  "RoomTimerDialog.this_call_will_end": "You're the only one here. This call will end in less than 2 minutes unless you choose to stay.",
  "Settings.camera_label": "Camera",
  "Settings.microphone_label": "Microphone",
  "Settings.speakers_label": "Speakers",
  "Settings.title": "Settings",
  "SocketErrorAlert.description": "Some collaboration features are being blocked by things like ad blockers, cookie blockers, or firewalls.",
  "SpecificChat.assign_to_me": "Assign to me",
  "SpecificChat.assigned_to": "This chat was assigned to {name}",
  "SpecificChat.close_and_archive": "Close and archive",
  "SpecificChat.closed": "This chat is closed",
  "SpecificChat.hide_profile": "Hide profile information",
  "SpecificChat.pre_chat_survey": "Pre-chat survey",
  "SpecificChat.profile": "Profile",
  "SpecificChat.reopen": "Reopen",
  "SpecificChat.show_profile": "Show profile information",
  "SpecificChat.start_audio_call": "Start an audio call",
  "SpecificChat.start_video_call": "Start a video call",
  "SpecificChat.stopped": "This chat was stopped",
  "SpecificChat.unassigned": "This chat is unassigned",
  "SpecificChat.viewer": "You're the only one viewing it",
  "SpecificChat.viewers": "You and {count} other {count, plural, one {person} other {people}} are currently viewing it",
  "SpecificChatActionsButton.close_chat": "Close chat and archive",
  "SpecificChatActionsButton.close_chat_description": "Are you sure you want to close this chat and archive it?",
  "SpecificChatActionsButton.close_chat_title": "Close and archive?",
  "SpecificChatActionsButton.yes_close_chat": "Yes, close and archive",
  "SpecificChatViewers.more": "+{count} more",
  "ToggleAppointmentDetailsButton.close": "Close Meeting Details",
  "ToggleAppointmentDetailsButton.details": "Meeting Details",
  "ToggleAudioButton.mute": "Mute",
  "ToggleAudioButton.unmute": "Unmute",
  "ToggleCaptionsButton.off": "Turn off captions",
  "ToggleCaptionsButton.on": "Turn on captions",
  "ToggleChangeBackgroundButton.close": "Close Change Background",
  "ToggleChangeBackgroundButton.open": "Change Background",
  "ToggleChangeBackgroundButton.unavailable_with_this_browser": "Unavailable with this browser",
  "ToggleChatButton.close": "Close Chat",
  "ToggleChatButton.open": "Chat",
  "ToggleCoBrowseButton.close_co_browse": "Close Co-browse",
  "ToggleDocumentsButton.close": "Close Documents",
  "ToggleDocumentsButton.documents": "Documents",
  "ToggleInternalNotesButton.close": "Close Internal Notes",
  "ToggleInternalNotesButton.notes": "Internal Notes",
  "ToggleMoreButton.more": "More",
  "TogglePeopleButton.close": "Close People",
  "TogglePeopleButton.open": "People",
  "ToggleScreenShareButton.co_browse_in_use": "Co-browse is active",
  "ToggleScreenShareButton.in_use": "Someone else is screen sharing",
  "ToggleScreenShareButton.share": "Share screen",
  "ToggleScreenShareButton.share_with_phone_participants": "Share screen (not visible to phone participants)",
  "ToggleScreenShareButton.stop_sharing": "Stop sharing",
  "ToggleScreenShareButton.unavailable_on_device": "Unavailable on this device",
  "ToggleScreenShareButton.warn_about_phone_participants": "Phone participants can't see shared screens",
  "ToggleSettingsButton.close_settings": "Close Settings",
  "ToggleSettingsButton.settings": "Settings",
  "ToggleVideoButton.off": "Turn off camera",
  "ToggleVideoButton.on": "Turn on camera",
  "TwilioComponentAlert.degraded_audio_description": "You may experience some disruption if you join by phone. We're working to fix this.",
  "TwilioComponentAlert.degraded_audio_title": "Joining by phone is having issues",
  "TwilioComponentAlert.degraded_video_description": "You may experience some disruption to your video during the call. We're working to fix this.",
  "TwilioComponentAlert.degraded_video_title": "Video display is having issues",
  "TwilioComponentAlert.unavailable_audio_description": "You can still join this call over video. We're working to fix this.",
  "TwilioComponentAlert.unavailable_audio_title": "Joining by phone is currently unavailable",
  "TwilioComponentAlert.unavailable_video_description": "You can still join this call using audio only. We're working to fix this.",
  "TwilioComponentAlert.unavailable_video_title": "Video is currently unavailable",
  "TwilioComponentAlert.under_maintenance_audio_description": "You can still join this call over video.",
  "TwilioComponentAlert.under_maintenance_audio_title": "Joining by phone is currently under maintenance",
  "TwilioComponentAlert.under_maintenance_video_description": "You can still join this call using audio only.",
  "TwilioComponentAlert.under_maintenance_video_title": "Video is currently under maintenance",
  "Ui.admit": "Admit",
  "Ui.close": "Close",
  "Ui.closed": "Closed",
  "Ui.continue": "Continue",
  "Ui.copied": "Copied!",
  "Ui.copy": "Copy",
  "Ui.count_more": "+ {count} more",
  "Ui.deny": "Deny",
  "Ui.details": "Details",
  "Ui.dismiss": "Dismiss",
  "Ui.download": "Download",
  "Ui.email": "Email",
  "Ui.go_back": "Go back",
  "Ui.got_it": "Got it",
  "Ui.hide_people": "Hide people",
  "Ui.loading": "Loading...",
  "Ui.more": "More",
  "Ui.next": "Next",
  "Ui.open": "Open",
  "Ui.other": "Other",
  "Ui.password": "Password",
  "Ui.refresh": "Refresh",
  "Ui.refresh_contents": "Refresh contents",
  "Ui.refreshed": "{name} is refreshed",
  "Ui.refreshing": "Refreshing",
  "Ui.remove": "Remove",
  "Ui.resend": "Resend",
  "Ui.send": "Send",
  "Ui.show_less": "Show less",
  "Ui.show_more": "Show more",
  "Ui.show_people": "Show people",
  "Ui.sign_in": "Sign In",
  "Ui.sign_in_failed_email_description": "Email and/or password were incorrect",
  "Ui.sign_in_failed_title": "Couldn't sign you in",
  "Ui.sign_in_failed_username_description": "Username and/or password were incorrect",
  "Ui.try_again": "Try again",
  "Ui.upload": "Upload",
  "Ui.username_or_email": "Username or Email",
  "Ui.your_name": "Your name",
  "UnsupportedBrowser.browser_suggestion": "Please try again using a newer browser such as Chrome or Firefox.",
  "UnsupportedBrowser.browser_suggestion_android": "Please try again using a newer version of Chrome or Firefox.",
  "UnsupportedBrowser.browser_suggestion_ios": "Please try again using a newer version of Safari.",
  "UnsupportedBrowser.browser_suggestion_mac": "Please try again using a newer version of Chrome, Firefox, Safari, or Edge.",
  "UnsupportedBrowser.browser_suggestion_windows": "Please try again using a newer version of Chrome, Firefox, or Edge.",
  "UnsupportedBrowser.browser_suggestion_windows_phone": "Please try again using a newer version of Edge.",
  "UnsupportedBrowser.description": "We can't connect you because your web browser doesn't support this type of video call.",
  "UnsupportedBrowser.title": "Your browser isn't supported",
  "Validation.email_required": "The email field is required",
  "Validation.email_valid": "The email must be a valid email address",
  "Validation.emails_required": "Add at least one email address",
  "Validation.emails_unique": "The email has already been provided",
  "Validation.full_name_required": "The full name field is required",
  "Validation.name_required": "The name field is required",
  "Validation.password_length": "Must be at least 10 characters long",
  "Validation.password_required": "The password field is required",
  "Validation.url_required": "The URL field is required",
  "Validation.url_valid": "The URL must be a valid URL",
  "Validation.username_or_email_required": "The username or email field is required",
  "VideoDegradedHeader.degraded": "Video is currently having issues. We're working to fix this.",
  "VideoDegradedHeader.unavailable": "Video is currently unavailable. We're working to fix this.",
  "VideoTrack.stop_sharing": "Stop sharing",
  "VideoTrack.you_are_sharing": "You're sharing your screen",
  "VortexDialog.ok": "Got it",
  "VortexDialog.stop_sharing": "Stop sharing",
  "VortexDialog.title": "Vortex effect?",
  "VortexDialog.vortex_description": "If you experience an infinity mirror on your screen, make sure you're not sharing the screen, window, or tab that has the video call. Try sharing just a tab or different window instead."
}
